<template>
  <aside class="h-screen fixed left-0 top-0 z-10 -translate-x-full md:translate-x-0 w-[295px] md:w-[86px] xl:w-[295px] py-4 px-6 bg-white shadow-main overflow-y-auto">
    <div class="flex flex-col h-full space-y-[30px]">
      <router-link to="/" class="inline-block w-10">
        <img src="@/assets/images/LogoSquare.png" alt="Logo" class="w-10 h-10">
      </router-link>
      <div class="w-full flex items-center md:justify-center xl:justify-start space-x-[10px]">
        <router-link to="/" class="w-6 h-6 min-w-[24px] text-center">
          <img src="@/assets/images/arrow-left.png" alt="Arrow left">
        </router-link>
        <h2 class="font-normal md:hidden xl:inline-block">Settings</h2>
      </div>
      <div class="rounded-2xl bg-white md:bg-transparent xl:bg-white shadow-main md:shadow-none xl:shadow-main py-[10px] md:py-0 md:space-y-1 xl:space-y-0 xl:py-[10px]">
        <router-link to="/settings/api-key" :class="{ 'bg-primary text-white' : tab == 'api_key', 'after:bg-check-green' : isApiValid }" class="w-full flex rounded items-center group px-4 md:px-[6px] xl:px-4 py-3 md:py-[6px] xl:py-3 hover:bg-primary hover:text-white space-x-[10px] after:ml-auto after:w-6 after:h-6 after:inline-block">
          <figure class="w-6 h-6 min-w-[24px] flex items-center justify-center">
            <img src="@/assets/images/key-black.png" alt="Key icon black" class="group-hover:hidden" v-if="tab == 'tables'">
            <img src="@/assets/images/key-white.png" alt="Key icon black" class="group-hover:block" :class="{ 'hidden' : tab == 'tables' }">
          </figure>
          <span class="inline-block md:hidden xl:inline-block">API key</span>
        </router-link>
        <router-link to="/settings/tables" :class="{ 'bg-primary text-white' : tab == 'tables' }" class="w-full flex rounded items-center group px-4 md:px-[6px] xl:px-4 py-3 md:py-[6px] xl:py-3 hover:bg-primary hover:text-white space-x-[10px]">
          <figure class="w-6 h-6 min-w-[24px] flex items-center justify-center">
            <img src="@/assets/images/settings-black.png" alt="Key icon black" class="group-hover:hidden" v-if="tab == 'api_key'">
            <img src="@/assets/images/settings-white.png" alt="Key icon black" class="group-hover:block" :class="{ 'hidden' : tab == 'api_key' }">
          </figure>
          <span class="inline-block md:hidden xl:inline-block">Tables</span>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "SettingsLeftSidebar",
  props: {
    tab: String,
    isApiValid: Boolean
  },
}
</script>

<style scoped>

</style>
