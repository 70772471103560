<template>
  <main class="bg-body min-h-screen w-full max-w-full overflow-hidden flex">
    <settings-left-sidebar tab="api_key" :isApiValid="isApiValid"></settings-left-sidebar>
    <div class="flex-grow max-w-full relative md:pl-[86px] xl:pl-[295px] pb-20">
      <Header></Header>
      <section class="py-4 pl-5 sm:pl-8 lg:pl-15 pr-5 sm:pr-6 lg:pr-9 space-y-30px relative">
        <h2>API Key Configuration</h2>
        <form @submit.prevent="onSaveForm()">
          <div class="space-y-15px w-[300px] max-w-full">
            <div class="space-y-[5px] w-full">
              <label for="data_api_key" class="font-bold text-label text-xs">Data API Key</label>
              <input type="text" id="data_api_key" name="data-api" v-model="apiKey" required="required" placeholder="e.g. Af50DDqtlx0imfnc8mVLWwsA" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
            </div>
            <div class="space-y-[5px] w-full">
              <label for="meta_api_key" class="font-bold text-label text-xs">Meta API Key</label>
              <input type="text" id="meta_api_key" name="data-api" v-model="metaApiKey" required="required" placeholder="e.g. zaCELgL" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
            </div>
            <div class="space-y-[5px] w-full">
              <label for="base_id" class="font-bold text-label text-xs">Base ID</label>
              <input type="text" id="base_id" name="data-api" v-model="baseId" required="required" placeholder="e.g. 31768921" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
            </div>
            <h2>Application</h2>
            <div class="space-y-[5px] w-full">
              <label for="app_name" class="font-bold text-label text-xs">Application Name</label>
              <input type="text" id="app_name" name="data-api" v-model="app_name" required="required" placeholder="UC Marketing Database" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
            </div>
          </div>
          <div class="w-[300px] max-w-full space-y-4 mtc-30">
            <button v-if="!is_loading" class="px-6 h-11 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Connect</button>
            <button v-if="is_loading" disabled class="px-6 h-11 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Connecting...</button>
          </div>
        </form>

<!--          <div class="bg-theme-red text-white rounded-2xl shadow-main py-5 px-5">-->
<!--            <div class="w-full flex space-x-15px">-->
<!--              <div class="flex-grow space-y-[2px]">-->
<!--                <h6 class="text-sm font-bold">Something went wrong</h6>-->
<!--                <p>The API Keys couldn't connect</p>-->
<!--              </div>-->
<!--              <button class="w-6 h-6 relative flex justify-center items-center">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34" class="w-full h-full -rotate-90">-->
<!--                  <circle cx="16" cy="16" r="15.9155" class="fill-transparent stroke-theme-red stroke-2" />-->
<!--                  <circle cx="16" cy="16" r="15.9155" class="fill-transparent stroke-white stroke-2 progress-bar__progress animate-progress" />-->
<!--                </svg>-->
<!--                <figure class="absolute">-->
<!--                  <img src="@/assets/images/close-white.png" alt="Close icon">-->
<!--                </figure>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </section>
      <figure class="absolute -left-32 md:-left-3 xl:left-44 -bottom-36 md:-bottom-32">
        <img src="@/assets/images/circle-frame.png" alt="Circle frame">
      </figure>
    </div>
  </main>
</template>

<script>
import Header from "@/shared/Header";
import SettingsLeftSidebar from "@/shared/SettingsLeftSidebar";
import jwt_encode from "jwt-encode";
import jwt_decode from "jwt-decode";
import { useApplicationStore} from "@/stores/application";
export default {
  name: "ApiKeySettings",
  components: {
    SettingsLeftSidebar,
    Header
  },
  data(){
    return {
      apiKey: '',
      metaApiKey: '',
      baseId: '',
      app_name: '',
      isApiValid: false,
      is_loading: false
    }
  },
  methods: {
    async apiRequest(method, uri, data, headers) {
      const token = await this.$auth0.getAccessTokenSilently();
      const url = process.env.VUE_APP_API_URL + uri;
      data = { token: jwt_encode(Object.assign({}, data, { api_key: process.env.VUE_APP_API_KEY }), process.env.VUE_APP_JWT_KEY) };
      headers = Object.assign({}, headers, {
        Authorization: 'Bearer ' + token
      });

      return this.$http.request({
        method: method,
        url: url,
        data: data,
        headers: headers
      });
    },
    onSaveForm(){
      this.is_loading = true;
      this.apiRequest('patch', '/api/settings/update/api-key', {
        airtable_api_key: this.apiKey,
        meta_api_key: this.metaApiKey,
        base_id: this.baseId,
        app_name: this.app_name }
      ).then(response => {
            this.is_loading = false;
            if (response.data.status) {
              this.isApiValid = response.data.connected;
              if (response.data.connected) {
                this.$notify({ type: 'bg-success', title: 'Great!', text: 'The connection was successful', id: Date.now(), duration: 5000 });
              }
              else{
                this.$notify({ type: 'bg-error', title: 'Something went wrong', text: 'The API Keys couldn\'t connect', id: Date.now(), duration: 5000 });
              }

              /**Set application name*/
              this.setApplicationName();
            }
            else{
              this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
            }
          });
    },
    setApplicationName(){
      useApplicationStore().setAppName(this.app_name);
    }
  },
  mounted() {
    this.apiRequest('post', '/api/settings/api-key').then(response => {
          if (response.data.status) {
            let data = jwt_decode(response.data.data)
            this.apiKey = data.api_key;
            this.metaApiKey = data.meta_api_key;
            this.baseId = data.base_id;
            this.isApiValid = data.is_valid;
            this.app_name = data.app_name;
            this.setApplicationName();
          }
        });
  }
}
</script>

<style scoped>

</style>
