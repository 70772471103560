<template>
  <main class="bg-body w-full max-w-full overflow-hidden">
    <div class="container px-6  py-16 sm:py-28 min-h-screen mx-auto relative  flex items-center">
      <div class="absolute top-0 left-auto md:left-[184px] right-0 md:right-auto w-3/5 md:w-auto">
        <img src="@/assets/images/arrow-frame-up.png" alt="Arrow frame up">
      </div>
      <div class="absolute top-[400px] md:top-[558px] left-0 w-1/4 sm:w-auto">
        <img src="@/assets/images/circle-frame.png" alt="Circle frame">
      </div>

      <div v-if="hasEmailSent" class="w-[320px] rounded-2xl bg-theme-red text-white text-center shadow-main p-30px space-y-5 sm:ml-20 relative z-10">
        <figure class="-mt-16 -mb-1">
          <img src="@/assets/images/LogoSquare.png" alt="Logo">
        </figure>
        <figure class="flex items-center justify-center">
          <img src="@/assets/images/star-arrow-up-red.png" alt="Star arrow up">
        </figure>
        <h2 class="font-normal capitalize">We sent you an Email</h2>
        <p class="-mx-2">Click on the link in the email we sent you.</p>
        <div class="w-full">
          <router-link to="/auth/login" class="text-label font-bold text-sm w-full px-6 bg-white hover:bg-theme-gray transition-all duration-300 rounded shadow-main h-12 flex items-center justify-center">Back to Login</router-link>
        </div>
      </div>

      <div v-if="!hasEmailSent" class="w-[320px] rounded-2xl bg-white shadow-main p-30px space-y-5 sm:ml-20 relative z-10">
        <figure class="-mt-16 -mb-1">
          <img src="@/assets/images/LogoSquare.png" alt="Logo">
        </figure>
        <h2 class="font-normal capitalize">Lost Password?</h2>
        <form class="space-y-5" @submit.prevent="onPasswordRecovery()">
          <div class="space-y-[5px] w-full">
            <label for="email" class="font-bold text-label text-xs">Email</label>
            <input type="email" id="email" name="email" required="required" v-model="email" placeholder="jamie@oliver.com" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
          </div>
          <div class="w-full space-y-4">
            <button  v-if="!isLoading" type="submit" class="w-full h-11 px-6 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Recover Password</button>
            <button v-if="isLoading" disabled class="w-full h-11 px-6 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Processing...</button>
          </div>
          <div class="w-full h-11 px-6 flex items-center justify-center">
            <router-link to="/auth/login" class="text-label font-bold text-sm hover:underline hover:decoration-2">Back to Login</router-link>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import jwt_encode from "jwt-encode";
import {useApplicationStore} from "@/stores/application";
export default {
  name: "ForgotPassword",
  data(){
    return {
      email: '',
      isLoading: false,
      hasEmailSent: false,
    }
  },
  beforeCreate(){
    useApplicationStore().setPageTitle();
  },
  methods: {
    onPasswordRecovery(){
      this.isLoading = true;
      this.$http.post(process.env.VUE_APP_API_URL + '/api/forget/password', { token: jwt_encode({ email: this.email, password: this.password, api_key: process.env.VUE_APP_API_KEY },process.env.VUE_APP_JWT_KEY) })
          .then(response => {
            this.isLoading = false;
            if (response.data.status) {
              this.hasEmailSent = true;
            }
            else{
              this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
            }
          });
    }
  }
}
</script>

<style scoped>

</style>
