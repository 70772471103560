<template>
  <header class="w-full flex py-15px px-5">
    <div class="flex items-center ml-auto space-x-4 relative">
      <figure class="rounded-full w-10 h-10 flex items-center justify-center bg-[#F7F7F7]" @click="onProfileDropdownToggle()">
        <img src="@/assets/images/user-48.png" alt="Jenny" class="w-full h-full object-cover object-center">
      </figure>
      <button class="flex w-6 h-6 items-center justify-center cursor-pointer" @click="onProfileDropdownToggle()">
        <img src="@/assets/images/chevron-down.png" alt="Chevron down" class="w-[10px] h-[6px]">
      </button>
      <div :class="{ hidden: !showProfileDropdown }" class="absolute right-0 top-full mt-3 z-10 bg-white rounded-2xl shadow-main py-1 space-y-2 min-w-[220px]">
        <router-link to="/settings/api-key" class="group flex items-center justify-end w-full px-8 py-3 space-x-4 hover:bg-primary hover:text-white rounded">
          <span class="inline-block">Settings</span>
          <figure class="w-6 h-6 flex items-center justify-center">
            <img src="@/assets/images/settings-black.png" alt="Key icon black" class="group-hover:hidden">
            <img src="@/assets/images/settings-white.png" alt="Key icon black" class="hidden group-hover:block">
          </figure>
        </router-link>
        <div class="w-full h-[1px] bg-[#11072C] opacity-10"></div>
        <a class="group flex items-center justify-end w-full px-8 py-3 space-x-4 hover:bg-primary hover:text-white rounded cursor-pointer" @click="logout">
          <span class="inline-block" @click="logout">Logout</span>
          <figure class="w-6 h-6 flex items-center justify-center">
            <img src="@/assets/images/close-black.png" alt="Close icon black" class="group-hover:hidden">
            <img src="@/assets/images/close-white.png" alt="Close icon black" class="hidden group-hover:block">
          </figure>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import {useAuth0} from "@auth0/auth0-vue";

export default {
  name: "Header",
  data(){
    return {
      showProfileDropdown: false
    }
  },
  setup() {
    const auth0 = useAuth0();

    return {
      logout() {
        auth0.logout({ returnTo: window.location.origin });
      }
    };
  },
  methods: {
    onProfileDropdownToggle(){
      this.showProfileDropdown = !this.showProfileDropdown;
    }
  }
}
</script>

<style scoped>

</style>
