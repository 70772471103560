import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import axios from "axios";
import jwt_encode from "jwt-encode";

export const useApplicationStore = defineStore('app_settings', {
    state: () => {
        return {
            app_settings: useStorage('app_settings', {})
        }
    },
    actions: {
        setAppName(name) {
            this.app_settings.app_name = name;
            document.title = this.app_settings.app_name;
        },
        getAppName() {
            return this.app_settings ? this.app_settings.app_name : '';
        },
        setPageTitle(apiToken) {
            if (!this.app_settings.app_name) {
                axios
                    .post(process.env.VUE_APP_API_URL + '/api/application/settings', { token: jwt_encode({ api_key: process.env.VUE_APP_API_KEY },process.env.VUE_APP_JWT_KEY) },
                        {
                            headers: {
                                Authorization: 'Bearer ' + apiToken
                            }
                        })
                    .then(response => {
                        this.setAppName(response.data.app_name);
                    })
            }

            this.setAppName(this.app_settings.app_name);
        }
    },
})
