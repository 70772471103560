<template>
  <main class="bg-body w-full max-w-full overflow-hidden">
    <div class="container px-6  py-16 sm:py-28 min-h-screen mx-auto relative  flex items-center">
      <div class="absolute top-0 left-auto md:left-[184px] right-0 md:right-auto w-3/5 md:w-auto">
        <img src="@/assets/images/arrow-frame-up.png" alt="Arrow frame up">
      </div>
      <div class="absolute top-[400px] md:top-[558px] left-0 w-1/4 sm:w-auto">
        <img src="@/assets/images/circle-frame.png" alt="Circle frame">
      </div>
      <div class="w-[320px] rounded-2xl bg-white shadow-main p-30px space-y-5 sm:ml-20 relative z-10">
        <figure class="-mt-16 -mb-1">
          <img src="@/assets/images/LogoSquare.png" alt="Logo">
        </figure>
        <h2 class="font-normal capitalize">Sign In</h2>
        <form @submit.prevent="onLogin()" class="space-y-5">
          <div class="w-full space-y-4">
            <button v-if="!isLoading" class="w-full h-11 px-6 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Sign In</button>
            <button v-if="isLoading" disabled class="w-full h-11 px-6 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Sign In...</button>
          </div>
          <div class="w-full h-8"></div>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import {useApplicationStore} from "@/stores/application";
import { useAuth0 } from '@auth0/auth0-vue';
export default {
  name: "LoginAuth",
  methods: {
    onLogin(){
      this.isLoading = true;
      this.login();
    }
  },
  data(){
    return {
      isLoading: false
    }
  },
  setup() {
    const auth0 = useAuth0();

    return {
      login() {
        auth0.loginWithRedirect();
      }
    };
  },
  beforeCreate(){
    useApplicationStore().setPageTitle();
  }
}
</script>

<style scoped>

</style>
