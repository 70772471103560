<template>
  <main class="bg-body w-full max-w-full overflow-hidden">
    <div class="container px-6  py-16 sm:py-28 min-h-screen mx-auto relative  flex items-center">
      <div class="absolute top-0 left-auto md:left-[184px] right-0 md:right-auto w-3/5 md:w-auto">
        <img src="@/assets/images/arrow-frame-up.png" alt="Arrow frame up">
      </div>
      <div class="absolute top-[400px] md:top-[558px] left-0 w-1/4 sm:w-auto">
        <img src="@/assets/images/circle-frame.png" alt="Circle frame">
      </div>
      <!-- Beginning change password box -->
      <div class="w-[320px] rounded-2xl bg-white shadow-main p-30px space-y-5 sm:ml-20 relative z-10">
        <figure class="-mt-16 -mb-1">
          <img src="@/assets/images/LogoSquare.png" alt="Logo">
        </figure>
        <h2 class="font-normal capitalize">Change Password</h2>
        <form class="space-y-5" autocomplete="off" @submit.prevent="onSavePassword">
          <div class="space-y-[5px] w-full">
            <label for="password" class="font-bold text-label text-xs">Password</label>
            <input :type="defaultPasswordType" id="password" name="password" v-model="newPassword" required="required" placeholder="password" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
          </div>
          <p class="text-label font-bold text-xs -mr-2" v-if="passwordError.length">
            {{ passwordError }}
          </p>
          <div class="space-y-[5px] w-full">
            <label for="passwordAgain" class="font-bold text-label text-xs">Password (again)</label>
            <div class="w-full relative">
              <input :type="defaultPasswordType" id="passwordAgain" name="passwordAgain" v-model="repeatPassword" required="required" placeholder="re-enter password" class="py-3 px-4 pr-12 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
              <button class="absolute right-4 top-3 w-6 h-6 cursor-pointer z-10" @click="onSwitchPasswordType">
                <img src="@/assets/images/eye-black.png" alt="Eye icon">
              </button>
            </div>
          </div>
          <div class="w-full h-[10px]"></div>
          <div class="w-full space-y-4">
            <button v-if="!isLoading" type="submit" class="w-full h-11 px-6 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Change Password</button>
            <button v-if="isLoading" disabled class="w-full h-11 px-6 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Processing...</button>
          </div>
          <div class="w-full h-8"></div>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import jwt_encode from "jwt-encode";
import {useApplicationStore} from "@/stores/application";

export default {
  name: "ChangePassword",
  data(){
    return {
      isLoading: false,
      newPassword: '',
      repeatPassword: '',
      passwordError: '',
      defaultPasswordType: 'password'
    }
  },
  methods: {
    onSavePassword(){
      if (this.newPassword != this.repeatPassword) {
        this.passwordError = 'Passwords do not match';
        return false;
      }

      if (this.newPassword.length < 10 || !/[a-z]/.test(this.newPassword) || !/[A-Z]/.test(this.newPassword) || !/\d/.test(this.newPassword)) {
        this.passwordError = 'Min 10 characters, 1 lowercase, 1 uppercase, and 1 numeric.';
        return false;
      }

      this.passwordError = '';
      this.isLoading = true;
      this.$http.patch(process.env.VUE_APP_API_URL + '/api/forget/password/save', { token: jwt_encode({ password_token: this.$route.params.id, password: this.newPassword, api_key: process.env.VUE_APP_API_KEY },process.env.VUE_APP_JWT_KEY) })
          .then(response => {
            this.isLoading = false;
            if (response.data.status) {
              this.$notify({ type: 'bg-success', title: 'Password updated successfully', id: Date.now(), duration: 5000 });
              this.$router.push({ name: 'login' })
            }
            else{
              this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
            }
          });
    },
    onSwitchPasswordType(){
      this.defaultPasswordType = this.defaultPasswordType == 'password' ? 'text' : 'password'
    }
  },
  beforeCreate(){
    useApplicationStore().setPageTitle();
  },
  mounted(){
    this.$http.post(process.env.VUE_APP_API_URL + '/api/check/password/token', { token: jwt_encode({ password_token: this.$route.params.id, api_key: process.env.VUE_APP_API_KEY },process.env.VUE_APP_JWT_KEY) })
        .then(response => {
          if (!response.data.status) {
            this.$notify({ type: 'bg-info', title: 'The token not found or expired', id: Date.now(), duration: 5000 });
            this.$router.push({ name: 'login' })
          }
        });
  }
}
</script>
