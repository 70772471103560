import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import DataManagement from "@/components/data-management/DataManagement";
import LoginAuth from "@/components/auth/LoginAuth";
import ChangePassword from "@/components/auth/ChangePassword";
import ForgotPassword from "@/components/auth/ForgotPassword";
import ApiKeySettings from "@/components/settings/ApiKeySettings";
import TableSettings from "@/components/settings/TableSettings";
import LogOut from "@/components/auth/LogOut";
import Notifications from '@kyvg/vue3-notification'
import Maska from 'maska'
import { createAuth0, authGuard } from '@auth0/auth0-vue';

const routes = [
    {
        path: '/auth/login',
        name: 'login',
        component: LoginAuth,
    },
    {
        path: '/auth/logout',
        name: 'logout',
        component: LogOut,
    },
    {
        path: '/auth/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/auth/change-password/:id',
        name: 'change-password',
        component: ChangePassword,
    },
    {
        path: '/settings/api-key',
        name: 'api-key',
        component: ApiKeySettings,
        beforeEnter: authGuard
    },
    {
        path: '/settings/tables',
        name: 'table-settings',
        component: TableSettings,
        beforeEnter: authGuard
    },
    {
        path: '/',
        name: 'data-management',
        component: DataManagement,
        beforeEnter: authGuard
    },
    {
        path: '/table/:id',
        name: 'selected-table',
        component: DataManagement,
        beforeEnter: authGuard
    },
    {
        path: '/table/:id/record/:record_id',
        name: 'selected-record',
        component: DataManagement,
        beforeEnter: authGuard
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeEach(function(to, from, next){
//     /**check validation*/
//     console.log(to.meta.requiresAuth);
//     // if (to.meta.requiresAuth && false) {
//     //     next('/auth')
//     // }
//     // else{
//     //     next();
//     // }
//     next();
// });

const pinia = createPinia()
const app = createApp(App)
app.use(router)
app.use(pinia)
app.use(VueAxios, axios)
app.use(Notifications)
app.use(Maska)
app.use(
    createAuth0({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE
    })
);
app.mount('#app')
