<template>
  <main class="bg-body min-h-screen w-full max-w-full overflow-hidden flex">
    <aside class="h-screen fixed left-0 top-0 z-10 -translate-x-full md:translate-x-0 w-[365px] bg-white shadow-main overflow-y-auto">
      <div class="flex flex-col h-full space-y-5">
        <div class="flex items-center w-full bg-white shadow-main px-[25px] py-15px space-x-5">
          <router-link to="/" class="inline-block w-10">
            <img src="@/assets/images/LogoSquare.png" alt="Logo" class="w-10 h-10">
          </router-link>
          <div class="flex-grow rounded relative">
            <button type="button" @click="toggleTableSwitch" v-if="selectedTable.table_id" class="w-full group flex items-center py-[10px] px-4 bg-blue-100 text-white focus:bg-white focus:text-default focus:shadow-main rounded">
              <dd>{{ selectedTable.name }}</dd>
              <figure class="w-6 h-6 min-w-fit flex items-center justify-center ml-auto">
                <img src="@/assets/images/chevron-down-white.png" alt="chevron down icon" class="group-focus:hidden">
                <img src="@/assets/images/chevron-down.png" alt="chevron down icon" class="hidden group-focus:block">
              </figure>
            </button>
            <ul class="absolute right-0 top-full mt-[1px] z-10 bg-white rounded shadow-main py-[10px] w-[255px] table-select-container" :class="{ 'hidden' : !tableSelectSwitchExpanded }">
              <li v-for="(item,index) in tables" :class="{ 'selected' : selectedTable.table_id == item.table_id }" :key="index" @click="tableRoute(item)" class="select-item flex items-center justify-start w-full px-[13px] py-2 space-x-4 hover:text-primary cursor-pointer">
                <figure class="w-6 h-6 flex items-center justify-center">
                  <img src="@/assets/images/check-blue.png" alt="Check icon" class="invisible">
                </figure>
                <span class="inline-block">{{ item.airtable_table_name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="px-5 w-full space-y-5">
          <div v-if="selectedTable.table_id && selectedTable.loaded" class="flex items-center">
            <dd class="mr-[10px] text-base text-default">{{ selectedTable.name }}</dd>
            <button type="button" @click="onNewRecord" class="ml-auto flex items-center group text-primary font-bold hover:text-theme-red space-x-[5px]">
              <figure class="w-6 h-6 min-w-fit flex items-center justify-center">
                <img src="@/assets/images/plus-blue.png" alt="Close icon" class="group-hover:hidden">
                <img src="@/assets/images/plus-red.png" alt="Close icon" class="hidden group-hover:block">
              </figure>
              <dd>New</dd>
            </button>
          </div>
          <div class="flex justify-center">
            <img v-if="isLoadingTableContent" src="@/assets/images/loader.gif" width="32">
          </div>
          <template v-if="!isLoadingTableContent">
            <div v-if="selectedTable.loaded" class="relative rounded-[10px] flex items-center">
              <button @click="onFilterRecords()" class="w-6 h-6 min-w-fit flex items-center justify-center absolute left-3">
                <img src="@/assets/images/search-black.png" alt="Search icon">
              </button>
              <input type="text" id="nav-search-box" v-model="selectedTable.keyword" name="nav-search-box" @keyup.enter="onFilterRecords()" @keydown.tab="onFilterRecords()" placeholder="Search" class="py-3 pl-10 pr-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
            </div>
            <ul class="w-full space-y-[5px]">
              <li v-for="(item, index) of sortRecords()" :key="index" @click="recordRoute(item)" :class="{ 'active' : selectedRecord.id == item.id }" class="group flex items-center w-full py-2 px-15px rounded text-default hover:text-primary cursor-pointer">
                <dd>{{ item.fields && item.fields[selectedTable.firstColumnName] ? item.fields[selectedTable.firstColumnName] : 'N/A' }}</dd>
                <figure class="w-6 h-6 min-w-fit flex items-center justify-center ml-auto">
                  <img src="@/assets/images/chevron-right-black.png" alt="Chevron right icon" class="group-hover:hidden">
                  <img src="@/assets/images/chevron-right-blue.png" alt="Chevron right icon" class="hidden group-hover:block">
                  <img src="@/assets/images/chevron-right-white.png" alt="Chevron right icon" class="hidden">
                </figure>
              </li>
            </ul>
          </template>
        </div>
        <template v-if="!isLoadingRecords && selectedTable.paginationOffset">
          <div class="space-y-4 flex justify-center">
            <button v-if="!isLoadingRecords" @click="onLoadMoreRecords" class="px-6 h-11 bg-primary hover:bg-blue-100 transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded shadow-main">Load More</button>
            <button v-if="isLoadingRecords" disabled="" class="px-6 h-11 bg-primary hover:bg-blue-100 transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Loading</button>
          </div>
        </template>
      </div>
    </aside>
    <div class="flex-grow max-w-full relative md:pl-[365px] pb-10">
      <Header></Header>
      <section class="py-4 pl-5 sm:pl-8 lg:pl-15 pr-5 sm:pr-6 lg:pr-9 space-y-30px relative">
        <template v-if="selectedRecord.id || recordDetails">
          <h2>{{ selectedRecord.name }}</h2>
          <form>
          <div class="xl:grid xl:grid-cols-2 xl:gap-10">
            <div class="space-y-[25px]">
              <img v-if="isLoadingRecordContent" src="@/assets/images/loader.gif" width="32">
              <template v-if="!isLoadingRecordContent && Object.keys(recordDetails).length">
                <template v-for="(item, index) in recordDetails.columns" :key="index">
                  <template v-if="readOnlyFieldsList.indexOf(item.airtable_column_type) == -1">
                    <template v-if="item.airtable_column_type == 'link_to_another_record'">
                      <div class="w-full transition-all hover:shadow-card rounded-[10px] bg-white py-15px px-5 space-y-[10px]">
                        <div class="flex items-center w-full">
                          <h6 class="text-sm font-bold text-label">{{ item.airtable_column_name }}</h6>
                          <button type="button" @click="onShowLinkRelationTableModal(item, index)" class="ml-auto flex items-center bg-secondary hover:bg-theme-red transition-all duration-200 space-x-[5px] rounded shadow-main pl-[6px] pr-15px py-[2px] font-bold text-xs text-white">
                            <figure class="w-6 h-6 min-w-fit flex items-center justify-center">
                              <img src="@/assets/images/plus-white.png" alt="Plus icon">
                            </figure>
                            <dd>Link Record</dd>
                          </button>
                        </div>
                        <div v-if="item.selected_value && !item.reference_table_items">
                          <img src="@/assets/images/loader.gif" width="32">
                        </div>
                        <template v-if="item.reference_table_items">
                          <template v-for="(ref_item, ref_index) in item.reference_table_items" :key="ref_index">
                            <div class="pt-[10px] pb-15px space-y-[5px]">
                              <div class="flex w-full">
                                <router-link :to="{ name: 'selected-record', params: {id: item.airtable_table_id, record_id: ref_item.id}}" class="flex text-primary font-bold text-base hover:underline mr-[10px]">
                                  {{ ref_item.name }}
                                  <img src="@/assets/images/link-icon-blue.png" alt="Link icon" class="ml-[10px] w-4 h-4 min-w-fit mt-1">
                                </router-link>
                                <button type="button" @click="deleteReferenceItem(item, ref_index)" class="w-6 h-6 min-w-fit flex items-center justify-center ml-auto">
                                  <img src="@/assets/images/trash-icon-black.png" alt="Trash icon">
                                </button>
                              </div>
                              <p class="text-info text-xs max-w-[90%] truncate">
                                {{ ref_item.description }}
                              </p>
                            </div>
                          </template>
                        </template>
                        <div v-if="item.reference_table_items == undefined || (!item.selected_value && !item.reference_table_items.length)" class="space-x-[10px] text-info flex items-center py-[3px]">
                          <figure class="flex items-center justify-center w-6 h-6">
                            <img src="@/assets/images/info-gray.png" alt="Info icon">
                          </figure>
                          <p>No entries</p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'single_line_text'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="text" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'long_text'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <textarea type="textarea" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full h-32 focus:outline-none focus:shadow-none focus:border-primary resize-none"></textarea>
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'attachment'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">
                          {{ item.airtable_column_name }}
                          <a @click.prevent="onAttachmentSelect(index)" class="cursor-pointer float-right flex items-center group text-primary font-bold hover:text-theme-red space-x-[5px]">
                            <figure class="w-6 h-6 min-w-fit flex items-center justify-center">
                              <img src="@/assets/images/plus-blue.png" alt="Close icon" class="group-hover:hidden">
                              <img src="@/assets/images/plus-red.png" alt="Close icon" class="hidden group-hover:block">
                            </figure>
                            <dd>New</dd>
                          </a>
                        </label>
                        <div class="w-full transition-all hover:shadow-card rounded-[10px] bg-white py-15px px-5 space-y-[10px]">
                          <div class="w-full divide-y divide-theme-gray">
                            <template v-if="item.selected_value != undefined && item.selected_value.length">
                              <div v-for="(attachment, ai) in item.selected_value" :key="ai" class="pt-[10px] pb-15px space-y-[5px]">
                                <div class="flex w-full">
                                  <a :href="attachment.url" target="_blank" class="flex text-primary font-bold text-base hover:underline mr-[10px]">
                                    {{ attachment.filename }}
                                    <img src="@/assets/images/link-icon-blue.png" alt="Link icon" class="ml-[10px] w-4 h-4 min-w-fit mt-1">
                                  </a>
                                  <button type="button" @click="onDeleteAttachment(item.selected_value, ai)" class="w-6 h-6 min-w-fit flex items-center justify-center ml-auto">
                                    <img src="@/assets/images/trash-icon-black.png" alt="Trash icon">
                                  </button>
                                </div>
                              </div>
                            </template>
                            <template v-if="item.selected_value == undefined || !item.selected_value.length">
                              <span>No attachments found</span>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'checkbox'">
                      <div class="space-y-[5px] w-full">
                        <label class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="checkbox" :id="'item_' + index" v-model="item.selected_value" class="sr-only peer">
                        <label :for="'item_' + index" class="flex w-4 items-center h-5 cursor-pointer font-normal text-label text-xs pl-[50px] relative before:absolute before:left-0 before:w-10 before:h-5 before:bg-theme-gray before:transition-all before:duration-500 before:rounded-[6px] peer-checked:before:bg-theme-red after:w-[15px] after:h-[15px] after:rounded-[4px] after:absolute after:top-[2px] after:bottom-[2px] after:left-[3px] after:transition-all after:duration-500 after:bg-white peer-checked:after:left-[22px]"></label>
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'multiple_select'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <v-select :options="getDropdownOptions(item)" v-model="item.selected_value" :multiple="true"></v-select>
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'single_select'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <v-select :options="getDropdownOptions(item)" v-model="item.selected_value"></v-select>
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'user'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="text" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter name, email or ID'" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'date'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <Datepicker v-model="item.selected_value" :is24="item.time_format == '24'" :enableTimePicker="item.include_time" :format="getColumnDateFormat(item.date_format, item.include_time)"></Datepicker>
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'phone_number'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="text" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'email'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="email" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'url'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="url" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'number'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="text" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'currency'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="text" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'percent'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="text" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'duration'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="text" v-maska="durationMaskFormat(item)" :id="'item_' + index" v-model="item.selected_value" :placeholder="'Enter ' + item.airtable_column_name" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'rating'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <star-rating v-model:rating="item.selected_value" :star-size="20" :max-rating="parseInt(item.rating)" :clearable="true" :show-rating="false" :rounded-corners="true" :border-width="4" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'barcode'">
                      <div class="space-y-[5px] w-full">
                        <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                        <input type="text" :id="'item_' + index" v-model="item.selected_value" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </div>
                    </template>
                    <template v-else-if="item.airtable_column_type == 'button'">

                    </template>
                  </template>
                </template>

                <div class="space-y-5 xl:space-y-0 xl:grid xl:grid-cols-2 xl:gap-5">
                  <template v-for="(item, index) in recordDetails.columns" :key="index">
                    <template v-if="readOnlyFieldsList.indexOf(item.airtable_column_type) != -1">
                      <template v-if="item.airtable_column_type == 'formula'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                      <template v-else-if="item.airtable_column_type == 'rollup'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                      <template v-else-if="item.airtable_column_type == 'count'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                      <template v-else-if="item.airtable_column_type == 'lookup'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                      <template v-else-if="item.airtable_column_type == 'created_time'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                      <template v-else-if="item.airtable_column_type == 'last_modified_time'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                      <template v-else-if="item.airtable_column_type == 'created_by'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                      <template v-else-if="item.airtable_column_type == 'last_modified_by'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                      <template v-else-if="item.airtable_column_type == 'autonumber'">
                        <div class="space-y-[5px] w-full">
                          <label :for="'item_' + index" class="font-bold text-label text-xs">{{ item.airtable_column_name }}</label>
                          <input type="text" :id="'item_' + index" v-model="item.selected_value" readonly="readonly" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary bg-body focus:bg-white">
                        </div>
                      </template>
                    </template>
                  </template>
                </div>

                  <div class="w-full flex items-center" v-if="recordDetails.columns.length">
                    <button type="button" @click="onSaveRecord" v-if="!isRecordSaveLoading" class="px-6 h-11 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded shadow-main">{{ selectedRecord.id ? 'Update' : 'Create' }}</button>
                    <button type="button" v-if="isRecordSaveLoading" disabled="disabled" class="px-6 h-11 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Processing</button>
                    <button type="button" @click="onDeleteRecord" v-if="showDeleteRecordBtn" class="flex justify-center space-x-[10px] ml-auto">
                      <figure class="w-6 h-6 min-w-fit flex items-center justify-center">
                        <img src="@/assets/images/trash-large16x.png" alt="Trash icon">
                      </figure>
                      <dd class="font-bold text-default">Delete</dd>
                    </button>
                  </div>
              </template>
            </div>
          </div>
          </form>
        </template>
      </section>
    </div>
  </main>
  <template v-if="showLinkRelationTableModal">
    <vue-final-modal
        v-model="showLinkRelationTableModal"
        classes="flex justify-center items-center"
        content-class="relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900 w488p"
    >
      <div class="w-full bg-white rounded-2xl shadow-main p-15px lg:p-30px space-y-30px">
        <div class="flex items-center">
          <h2 class="mr-[10px]">Add {{ selectedReferenceColumn.reference_table_name }}</h2>
          <button type="button" @click="onCloseRelationTableModal" class="ml-auto flex items-center group text-info font-bold hover:text-default space-x-[5px]">
            <figure class="w-6 h-6 min-w-fit flex items-center justify-center">
              <img src="@/assets/images/close-gray.png" alt="Close icon" class="group-hover:hidden">
              <img src="@/assets/images/close-black.png" alt="Close icon" class="hidden group-hover:block">
            </figure>
            <dd>Close</dd>
          </button>
        </div>
        <div v-if="selectedReferenceColumn.filter_by?.length" class="space-y-[5px]">
          <b>Filter By:</b>
          <div class="w-full" v-for="(item,index) in selectedReferenceColumn.filter_by" :key="index">
            <input type="checkbox" :id="'filter_by_' + index" v-model="item.selected" /> {{ item.airtable_table_name }}
          </div>
        </div>
        <div class="relative rounded-[10px] flex items-center">
          <button type="button" @click="onLoadReferenceRecords(true)" class="w-6 h-6 min-w-fit flex items-center justify-center absolute left-3">
            <img src="@/assets/images/search-black.png" alt="Search icon">
          </button>
          <input type="text" v-model="selectedReferenceColumn.relation_table_records.keyword" @keyup.enter="onLoadReferenceRecords(true)" @keydown.tab="onLoadReferenceRecords(true)" id="search-box" name="search-box" placeholder="Search by name" class="py-3 pl-10 pr-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
        </div>
        <template v-if="selectedReferenceColumn.relation_table_records.records.length">
          <ul class="reference-container w-full space-y-[5px]">
            <li @click="onSelectReferenceItem(item)" v-for="(item,index) in selectedReferenceColumn.relation_table_records.records" :key="index" :class="{ 'selected-reference-record': isReferenceRecordSelectedIndex(item.id) != -1 }" class="pt-[10px] pb-15px px-15px space-y-[5px] rounded hover:bg-theme-orange-0 cursor-pointer">
              <div class="flex w-full">
                <a class="flex text-default font-bold text-base mr-[10px]">
                  {{ item.fields[selectedReferenceColumn.ref_column_name] ? item.fields[selectedReferenceColumn.ref_column_name] : 'N/A' }}
                  <img src="@/assets/images/link-icon-blue.png" alt="Link icon" class="ml-[10px] w-4 h-4 min-w-fit mt-1">
                </a>
              </div>
              <p v-if="item.fields.Description" class="text-info text-xs max-w-[90%] truncate">
                {{ item.fields.Description }}
              </p>
            </li>
          </ul>
          <div class="flex justify-center" v-if="!selectedReferenceColumn.relation_table_records.loadingRecords && selectedReferenceColumn.relation_table_records.offset">
            <button type="button" @click="onLoadReferenceRecords(false)" class="px-6 h-11 bg-primary hover:bg-blue-100 transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded shadow-main">Load More</button>
          </div>
        </template>
        <div class="flex justify-center" v-if="selectedReferenceColumn.relation_table_records.loadingRecords">
          <img src="@/assets/images/loader.gif" width="32">
        </div>
        <template v-if="!selectedReferenceColumn.relation_table_records.loadingRecords && !selectedReferenceColumn.relation_table_records.records.length">
          <div class="flex justify-center">
            No records found
          </div>
        </template>
        <div class="flex items-center w-full">
          <template v-if="selectedReferenceColumn.relation_table_records.selectedRecords.length">
            <button v-if="showLinkRelationSaveBtn" @click="saveSelectedReferences" class="px-6 h-11 bg-primary hover:bg-blue-100 transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded shadow-main">Link Selected</button>
          </template>
          <button type="button" class="space-x-[10px] text-default text-xs font-bold flex items-center py-[3px] ml-auto">
            <figure class="flex items-center justify-center w-6 h-6 relative group">
              <img src="@/assets/images/question-black.png" alt="Question icon">
              <div class="hidden group-hover:block w-[190px] absolute left-1/2 -translate-x-1/2 top-6 before:border-l-8 before:border-r-8  before:border-l-transparent before:border-r-transparent before:border-b-8 before:border-b-primary-black before:block before:ml-auto before:mr-auto text-center before:w-0 before:h-0">
                <div class="w-full rounded bg-primary-black text-left font-normal px-3 py-2 text-white text-xs">
                  <p>Please use the top left selector to add a new record.</p>
                </div>
              </div>
            </figure>
            <p>Add New</p>
          </button>
        </div>
      </div>
    </vue-final-modal>
  </template>
  <vue-final-modal
      v-model="showDeleteConfirmModal"
      classes="flex justify-center items-center"
      content-class="relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900"
  >
    <div class="rounded-2xl bg-white shadow-main w-[450px] max-w-full">
      <div class="p-[25px] space-y-[25px]">
        <div class="flex items-center">
          <h2 class="mr-4">
            Confirmation Required
          </h2>
          <button type="button" @click="onCloseDeleteConfirmModal()" class="ml-auto w-6 h-6 flex justify-center items-center">
            <img src="@/assets/images/close-black.png" alt="Close icon">
          </button>
        </div>
        <div class="w-full space-y-5">
          <div class="alert-warning">
            Are you sure you want to delete this record?
          </div>
        </div>
        <div class="space-y-[5px] w-full">
          <div class="w-full flex items-center">
            <button type="button" v-if="!isRecordDeleteProcessing" @click="onCloseDeleteConfirmModal()" class="flex justify-center space-x-[10px]">
              <dd class="font-bold text-default">Cancel</dd>
            </button>
            <button v-if="isRecordDeleteProcessing" type="button" disabled="disabled" class="px-6 h-11 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded ml-auto">Processing</button>
            <button v-if="!isRecordDeleteProcessing" type="button" @click="onDeleteRecordProcess()" class="px-6 h-11 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded shadow-main ml-auto">Yes, Delete</button>
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
  <input type="file" @change="onFileSelect($event)" ref="file_picker" accept=".jpg,.jpeg,.png,.pdf,.csv,.xslx" class="hidden">
</template>
<script>
import { useApplicationStore } from "@/stores/application";
import Header from "@/shared/Header";
import jwt_encode from "jwt-encode";
import StarRating from 'vue-star-rating'
import Datepicker from '@vuepic/vue-datepicker'
import { VueFinalModal } from 'vue-final-modal'
import vSelect from "vue-select";
import '@vuepic/vue-datepicker/dist/main.css';
import "vue-select/dist/vue-select.css";
import {useAuth0} from "@auth0/auth0-vue";
export default {
  name: "DataManagement",
  components: {
    Header,
    StarRating,
    Datepicker,
    VueFinalModal,
    "v-select" : vSelect
  },
  data(){
    return {
      /**Tables*/
      isLoadingTableContent: true,
      tables: [],
      selectedTable: {
        table_id: null,
        name: null,
        paginationOffset: null,
        keyword: null,
        firstColumnName: null
      },
      records: [],
      recordIds: [],
      isLoadingRecords: false,
      selectedRecord: {
        name: '',
        id: ''
      },
      tableSelectSwitchExpanded: false,
      isRecordSaveLoading: false,
      isLoadingRecordContent: false,
      recordDetails: {},
      showDeleteRecordBtn: false,
      showLinkRelationTableModal: false,
      showLinkRelationSaveBtn: true,
      selectedReferenceColumn: {},
      readOnlyFieldsList: ['formula','rollup','count','lookup','created_time','last_modified_time','created_by','last_modified_by','autonumber'],
      showDeleteConfirmModal: false,
      isRecordDeleteProcessing: false,
      apiToken: ''
    }
  },
  methods: {
    async apiRequest(method, uri, data, headers) {
      const token = await this.$auth0.getAccessTokenSilently();
      const url = process.env.VUE_APP_API_URL + uri;
      data = { token: jwt_encode(Object.assign({}, data, { api_key: process.env.VUE_APP_API_KEY }), process.env.VUE_APP_JWT_KEY) };
      headers = Object.assign({}, headers, {
        Authorization: 'Bearer ' + token
      });

      return this.$http.request({
        method: method,
        url: url,
        data: data,
        headers: headers
      });
    },
    onLoadMoreRecords(){
      this.isLoadingRecords = true;

      this.apiRequest('post', '/api/table/records', {
        table_id: this.selectedTable.table_id,
        table: this.selectedTable.name,
        keyword: this.selectedTable.keyword,
        first_column_name: this.selectedTable.firstColumnName,
        offset: this.selectedTable.paginationOffset
      }).then(response => {
            this.isLoadingRecords = false;
            this.isLoadingTableContent = false;
            if (response.data.status) {
              for (let i=0; i<response.data.records.length; i++) {
                if (this.recordIds.indexOf(response.data.records[i].id) == -1) {
                  this.records.push(response.data.records[i]);
                  this.recordIds.push(response.data.records[i].id);
                }
              }
              this.selectedTable.paginationOffset = response.data.offset;
              this.selectedTable.loaded = true;
            }
            else{
              this.selectedTable.loaded = false;
              this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
            }
          });
    },
    onFilterRecords(){
      this.records = [];
      this.recordIds = [];
      this.selectedTable.paginationOffset = null;
      this.onLoadMoreRecords();
    },
    toggleTableSwitch(){
      this.tableSelectSwitchExpanded = !this.tableSelectSwitchExpanded;
    },
    tableRoute(item) {
      this.$router.push({ name: 'selected-table', params: { id: item.airtable_table_id } });
    },
    recordRoute(item) {
      this.$router.push({ name: 'selected-record', params: {
          id: this.selectedTable.airtable_table_id,
          record_id: item.id
        } });
    },
    onTableSelect(item){
      this.tableSelectSwitchExpanded = false;
      this.selectedTable = {
        airtable_table_id: item.airtable_table_id,
        table_id: item.table_id,
        name: item.airtable_table_name,
        paginationOffset: null,
        keyword: null,
        firstColumnName: item.first_column_name,
        loaded: false
      };

      this.records = [];
      this.recordIds = [];
      this.selectedRecord.id = null;
      this.selectedRecord.name = '';
      this.isLoadingTableContent = true;
      this.isLoadingRecordContent = false;
      this.recordDetails = {};
      this.onLoadMoreRecords();
    },
    getNameFromItem(item, default_value = '') {
      return item.fields?.[this.selectedTable.firstColumnName] || default_value;
    },
    onSelectRecord(item) {
      this.selectedRecord.name = this.getNameFromItem(item);
      this.selectedRecord.id = item.id;
      this.isLoadingRecordContent = true;

      this.apiRequest('post','/api/record/details', {
        table_id: this.selectedTable.table_id,
        table_name: this.selectedTable.name,
        record_id: this.selectedRecord.id
      }).then(response => {
            this.isLoadingRecordContent = false;
            if (response.data.status) {
              if (response.data.airtable_record) {
                /**Create key value pairs*/
                let airtable_records = {};
                for (let i in response.data.airtable_record.fields) {
                  airtable_records[i] = response.data.airtable_record.fields[i];
                }

                let date_obj;
                let date_format;

                /**Assign Values*/
                for (let i=0; i<response.data.columns.length; i++) {
                  let selected_value = airtable_records[response.data.columns[i].airtable_column_name] == undefined ? null : airtable_records[response.data.columns[i].airtable_column_name];
                  switch (response.data.columns[i].airtable_column_type){
                    case 'user':
                    case 'created_by':
                    case 'last_modified_by':
                      selected_value = selected_value ? selected_value.name : null;
                    break;
                    case 'barcode':
                      selected_value = selected_value ? selected_value.text : null;
                    break;
                    case 'lookup':
                      if (selected_value != null) {
                        if(selected_value == 'boolean') {
                          selected_value = selected_value.toString();
                        }
                      }
                    break;
                    case 'rating':
                      selected_value = selected_value ? selected_value : 0;
                    break;
                    case 'percent':
                      if (selected_value != null) {
                        selected_value = parseFloat(selected_value * 100).toFixed(response.data.columns[i].precision)
                      }
                    break;
                    case 'duration':
                      if (selected_value != null) {
                        let sec_num = parseInt(selected_value, 10)
                        let hours   = Math.floor(sec_num / 3600)
                        let minutes = Math.floor(sec_num / 60) % 60
                        let seconds = sec_num % 60

                        let hour_minute_format = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
                        let seconds_format = (seconds < 10) ? '0' + seconds : seconds
                        let split_ms_obj = selected_value.toString().split('.');

                        switch (response.data.columns[i].duration) {
                          case 'h:mm':
                            selected_value = hour_minute_format;
                          break;
                          case 'h:mm:ss':
                            selected_value = hour_minute_format + ':' + seconds_format;
                          break;
                          case 'h:mm:ss.S':
                            selected_value = hour_minute_format + ':' + seconds_format + '.' + (split_ms_obj.length > 1 ? split_ms_obj[1] : '0');
                          break;
                          case 'h:mm:ss.SS':
                            selected_value = hour_minute_format + ':' + seconds_format + '.' + (split_ms_obj.length > 1 ? split_ms_obj[1] : '00');
                          break;
                          case 'h:mm:ss.SSS':
                            selected_value = hour_minute_format + ':' + seconds_format + '.' + (split_ms_obj.length > 1 ? split_ms_obj[1] : '000');
                          break;
                        }
                      }
                    break;
                    case 'number':
                      if (selected_value != null && response.data.columns[i].number_format == 'decimal') {
                        selected_value = parseFloat(selected_value).toFixed(response.data.columns[i].precision)
                      }
                    break;
                    case 'currency':
                      if (selected_value != null) {
                        selected_value = response.data.columns[i].symbol + '' + parseFloat(selected_value).toFixed(response.data.columns[i].precision)
                      }
                    break;
                    case 'created_time':
                    case 'last_modified_time':
                      if (selected_value != null) {
                        date_obj = new Date(selected_value);
                        date_format = ("00" + (date_obj.getMonth() + 1)).slice(-2) + "/" + ("00" + date_obj.getDate()).slice(-2) + "/" + date_obj.getFullYear();
                        if (selected_value.length > 10) {
                          date_format += ' ' + ("00" + date_obj.getHours()).slice(-2) + ":" + ("00" + date_obj.getMinutes()).slice(-2);
                        }
                        selected_value = date_format;
                      }
                    break;
                    case 'link_to_another_record': {
                      this.apiRequest('post', '/api/reference/selected/records', {
                        table_name: response.data.columns[i].reference_table_name,
                        ids: selected_value
                      }).then(resp => {
                            if (resp.data.status) {
                                let resp_items = [];
                                for (let j=0; j<resp.data.records.length; j++) {
                                  if (resp.data.records[j].fields[response.data.columns[i].ref_column_name]) {
                                    resp_items.push({
                                      id: resp.data.records[j].id,
                                      name: resp.data.records[j].fields[response.data.columns[i].ref_column_name],
                                      description: resp.data.records[j].fields.Description
                                    });
                                  }
                                }

                                this.recordDetails.columns[i].reference_table_items = resp_items;
                                if (!resp_items.length) {
                                  this.recordDetails.columns[i].selected_value = null;
                                  selected_value = null;
                                }
                            }
                            else{
                              this.recordDetails.columns[i].selected_value = null;
                              selected_value = null;
                            }
                          });
                    }
                    break;
                  }

                  response.data.columns[i].selected_value = selected_value;
                }

                this.showDeleteRecordBtn = true;
                this.recordDetails = {
                  airtable_record_id: response.data.airtable_record.id,
                  airtable_records: airtable_records,
                  columns: response.data.columns
                };

                this.selectedRecord.name = this.getNameFromItem(response.data.airtable_record, 'N/A');
              }
            }
            else{
              this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
            }
          });
    },
    onDeleteAttachment(items, index){
      items.splice(index,1);
    },
    getColumnDateFormat(type, include_time){
      let format;
      switch (type) {
        case 'local':
        case 'us':
          format = 'MM/dd/yyyy';
        break;
        case 'eu':
          format = 'dd/MM/yyyy';
        break;
        case 'friendly':
          //unable to cover this scenario?
          format = 'dd/MM/yyyy';
        break;
        case 'iso':
          format = 'yyyy-MM-dd';
        break;
      }

      if (include_time) {
        format += ' HH:mm';
      }

      return format;
    },
    getDropdownOptions(item) {
      let options = [];
      if (item.multiple_select_options) {
        options = JSON.parse("[" + item.multiple_select_options.slice(1, item.multiple_select_options.length-1) + "]");
      }
      return options;
    },
    /**Reference Items*/
    deleteReferenceItem(item, index) {
      item.reference_table_items.splice(index,1);
      if (!item.reference_table_items.length) {
        item.selected_value = null;
      }
    },
    onShowLinkRelationTableModal(item, index) {
      this.selectedReferenceColumn = {
        ...item,
        relation_table_records: {
          records: [],
          loadingRecords: true,
          offset: null,
          selectedRecords: [],
          keyword: null,
          columnIndex: index
        }
      }

      this.showLinkRelationTableModal = true;
      this.onLoadReferenceRecords(false);
    },
    onLoadReferenceRecords(clear_records = false){
      /**Clear State*/
      if (clear_records) {
        this.selectedReferenceColumn.relation_table_records.offset = null;
        this.selectedReferenceColumn.relation_table_records.records = [];
      }

      /**Load Records*/
      this.selectedReferenceColumn.relation_table_records.loadingRecords = true;

      if (this.selectedReferenceColumn.filter_by) {
        this.selectedReferenceColumn.filter_by.map((filter) => {
          this.recordDetails.columns.find((column) => {
            if (column.column_id === filter.column_id) {
              filter.records = column.reference_table_items;
            }
          });
        });
      }

      this.apiRequest('post', '/api/load/reference/records', {
        column_id: this.selectedReferenceColumn.column_id,
        reference_table_name: this.selectedReferenceColumn.reference_table_name,
        reference_column_name: this.selectedReferenceColumn.ref_column_name,
        reference_table_id: this.selectedReferenceColumn.reference_table_id,
        offset: this.selectedReferenceColumn.relation_table_records.offset,
        keyword: this.selectedReferenceColumn.relation_table_records.keyword,
        filter_by: this.selectedReferenceColumn.filter_by
      }).then(response => {
            this.selectedReferenceColumn.relation_table_records.loadingRecords = false;
            if (response.data.status) {
              this.selectedReferenceColumn.relation_table_records.records = this.selectedReferenceColumn.relation_table_records.records.concat(response.data.records);
              this.selectedReferenceColumn.relation_table_records.offset = response.data.offset;
            }
            else{
              this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
            }
          });
    },
    onCloseRelationTableModal() {
      this.showLinkRelationTableModal = false;
    },
    onSelectReferenceItem(item) {
      let is_selected_index = this.isReferenceRecordSelectedIndex(item.id);
      let record = {
        id: item.id,
        name: item.fields[this.selectedReferenceColumn.ref_column_name] ? item.fields[this.selectedReferenceColumn.ref_column_name] : 'N/A',
        description: item.fields.Description
      };

      if (this.selectedReferenceColumn.allow_multiple_references) {
        if (is_selected_index == -1) {
          this.selectedReferenceColumn.relation_table_records.selectedRecords.push(record);
        }
        else{
          this.selectedReferenceColumn.relation_table_records.selectedRecords.splice(is_selected_index,1);
        }
      }
      else{
        if (is_selected_index == -1) {
          this.selectedReferenceColumn.relation_table_records.selectedRecords = [record];
        }
        else{
          this.selectedReferenceColumn.relation_table_records.selectedRecords = [];
        }
      }
    },
    isReferenceRecordSelectedIndex(id) {
      for (let i=0; i<this.selectedReferenceColumn.relation_table_records.selectedRecords.length; i++) {
        if (this.selectedReferenceColumn.relation_table_records.selectedRecords[i].id == id) {
          return i;
        }
      }

      return -1;
    },
    saveSelectedReferences() {
      for (let i=0; i<this.selectedReferenceColumn.relation_table_records.selectedRecords.length; i++) {
        let match_found = false;
        if (this.recordDetails.columns[this.selectedReferenceColumn.relation_table_records.columnIndex].reference_table_items == undefined) {
          this.recordDetails.columns[this.selectedReferenceColumn.relation_table_records.columnIndex].reference_table_items = [];
        }
        else{
          for (let j=0; j<this.recordDetails.columns[this.selectedReferenceColumn.relation_table_records.columnIndex].reference_table_items.length; j++) {
            if (this.recordDetails.columns[this.selectedReferenceColumn.relation_table_records.columnIndex].reference_table_items[j].id == this.selectedReferenceColumn.relation_table_records.selectedRecords[i].id) {
              match_found = true;
            }
          }
        }

        if (!match_found) {
          if (this.selectedReferenceColumn.allow_multiple_references) {
            this.recordDetails.columns[this.selectedReferenceColumn.relation_table_records.columnIndex].reference_table_items.push(this.selectedReferenceColumn.relation_table_records.selectedRecords[i]);
          }
          else{
            this.recordDetails.columns[this.selectedReferenceColumn.relation_table_records.columnIndex].reference_table_items = [this.selectedReferenceColumn.relation_table_records.selectedRecords[i]];
          }
        }
      }

      this.showLinkRelationTableModal = false;
    },
    /**Process Attachments*/
    onAttachmentSelect(index) {
      this.recordDetails.activeColumnIndex = index;
      this.$refs.file_picker.click();
    },
    async onFileSelect(event) {
      const token = await this.$auth0.getAccessTokenSilently();
      /**Read imported files*/
      this.$http.post(process.env.VUE_APP_API_URL + '/api/upload/attachment', { file: event.target.files[0], token: jwt_encode({ api_key: process.env.VUE_APP_API_KEY },process.env.VUE_APP_JWT_KEY) }, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status) {
          if (!this.recordDetails.columns[this.recordDetails.activeColumnIndex].selected_value) {
            this.recordDetails.columns[this.recordDetails.activeColumnIndex].selected_value = [];
          }

          this.recordDetails.columns[this.recordDetails.activeColumnIndex].selected_value.push({
            filename: response.data.original_file_name,
            file_location: response.data.file_location,
            url: response.data.url,
            local_upload: true
          });
        }
        else{
          this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
        }
      });
    },
    onSaveRecord() {
      for (let i=0; i<this.recordDetails.columns.length; i++) {
        if (['percent','duration','number','currency'].indexOf(this.recordDetails.columns[i].airtable_column_type) != -1 && this.recordDetails.columns[i].selected_value != null) {
          let selected_value = this.recordDetails.columns[i].selected_value.toString().trim();
          switch (this.recordDetails.columns[i].airtable_column_type) {
            case 'percent':
              if (isNaN(selected_value)) {
                this.$notify({ type: 'bg-error', title: this.recordDetails.columns[i].airtable_column_name + ' number is not valid', id: Date.now(), duration: 5000 });
                return false;
              }
            break;
            case 'duration':
              selected_value = selected_value.replaceAll(':','').replaceAll('.','');
              if (!/^\d+$/.test(selected_value)) {
                this.$notify({ type: 'bg-error', title: this.recordDetails.columns[i].airtable_column_name + ' format is not valid, it should match ' + this.recordDetails.columns[i].duration, id: Date.now(), duration: 5000 });
                return false;
              }
            break;
            case 'number':
              if (this.recordDetails.columns[i].number_format == 'decimal') {
                if (isNaN(selected_value)) {
                  this.$notify({ type: 'bg-error', title: this.recordDetails.columns[i].airtable_column_name + ' number is not valid', id: Date.now(), duration: 5000 });
                  return false;
                }
              }
              else{
                if (!Number.isInteger(selected_value)) {
                  this.$notify({ type: 'bg-error', title: this.recordDetails.columns[i].airtable_column_name + ' number is not valid', id: Date.now(), duration: 5000 });
                  return false;
                }
              }

              if (!this.recordDetails.columns[i].allow_negative_numbers && this.recordDetails.columns[i].selected_value < 0) {
                this.$notify({ type: 'bg-error', title: this.recordDetails.columns[i].airtable_column_name + ' does not allow negative numbers', id: Date.now(), duration: 5000 });
                return false;
              }
            break;
            case 'currency':
              if (this.recordDetails.columns[i].symbol) {
                selected_value = this.recordDetails.columns[i].selected_value.replaceAll(this.recordDetails.columns[i].symbol,'');
                if (isNaN(selected_value)) {
                  this.$notify({ type: 'bg-error', title: this.recordDetails.columns[i].airtable_column_name + ' number is not valid', id: Date.now(), duration: 5000 });
                  return false;
                }
              }
            break;
          }
        }
      }

      this.isRecordSaveLoading = true;
      this.showDeleteRecordBtn = false;

      this.apiRequest('patch', '/api/save/record', {
        airtable_record_id: this.recordDetails.airtable_record_id,
        table_id: this.selectedTable.table_id,
        table_name: this.selectedTable.name,
        columns: this.recordDetails.columns
      }).then((response) => {
        if (response.data.status) {
          this.isRecordSaveLoading = false;
          this.showDeleteRecordBtn = true;
          /**If Update*/
          if (this.recordDetails.airtable_record_id) {
            this.$notify({ type: 'bg-success', title: 'Record updated successfully', id: Date.now(), duration: 5000 });
            this.selectedRecord.name = response.data.record.fields && response.data.record.fields[this.selectedTable.firstColumnName] ? response.data.record.fields[this.selectedTable.firstColumnName] : 'N/A';
            this.records.map(item => {
              if (item.id == this.recordDetails.airtable_record_id) {
                item.fields[this.selectedTable.firstColumnName] = this.selectedRecord.name;
              }
            });
          }
          else{
            this.records.push(response.data.record);
            this.recordIds.push(response.data.record.id);
            this.$notify({ type: 'bg-success', title: 'Record created successfully', id: Date.now(), duration: 5000 });
            this.onNewRecord();
          }
        }
        else{
          this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
        }
      });
    },
    onNewRecord() {
      this.showDeleteRecordBtn = false;
      this.selectedRecord = {
        name: 'New Record',
        id: null
      };
      this.recordDetails = {
        columns: []
      };

      this.isLoadingRecordContent = true;
      this.apiRequest('post', '/api/table/columns', {
        table_id: this.selectedTable.table_id
      }).then(response => {
        this.isLoadingRecordContent = false;
        if (response.data.status) {
          this.recordDetails.columns = response.data.columns.filter(item => this.readOnlyFieldsList.indexOf(item.airtable_column_type) == -1);
          // this.recordDetails.columns = response.data.columns;
        }
        else{
          this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
        }
      });
    },
    sortRecords() {
      let table_first_column = this.selectedTable.firstColumnName;
      return this.records.sort(function(a, b) {
        let first_column = a.fields[table_first_column] ? a.fields[table_first_column].toLowerCase() : 'N/A';
        let last_column = b.fields[table_first_column] ? b.fields[table_first_column].toLowerCase() : 'N/A';
        return first_column.localeCompare(last_column);
      });
    },
    onDeleteRecord() {
      this.isRecordDeleteProcessing = false;
      this.showDeleteConfirmModal = true;
    },
    onDeleteRecordProcess() {
      this.isRecordDeleteProcessing = true;
      this.apiRequest('post', '/api/record/delete', {
        table_name: this.selectedTable.name,
        record_id: this.selectedRecord.id
      }).then(response => {
        if (response.data.status) {
          let index_num = this.recordIds.indexOf(this.selectedRecord.id);
          if (index_num != -1) {
            this.recordIds.splice(index_num,1);
          }
          this.records = this.records.filter(item => item.id != this.selectedRecord.id);
          this.selectedRecord = {
            name: '',
            id: null
          };
          this.recordDetails = {
            columns: []
          };
          this.showDeleteConfirmModal = false;
          this.$notify({ type: 'bg-success', title: 'Record deleted successfully', id: Date.now(), duration: 5000 });
        }
        else{
          this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
        }
      });


    },
    onCloseDeleteConfirmModal() {
      this.showDeleteConfirmModal = false;
    },
    editableColumns() {
      return this.recordDetails.columns.filter(item => this.readOnlyFieldsList.indexOf(item.airtable_column_type) == -1);
    },
    readOnlyColumns() {
      return this.recordDetails.columns.filter(item => this.readOnlyFieldsList.indexOf(item.airtable_column_type) != -1);
    },
    durationMaskFormat(item) {
      let format = '#';
      switch(item.duration) {
        case 'h:mm':
          format = '##:##';
        break;
        case 'h:mm:ss':
          format = '##:##:##';
        break;
        case 'h:mm:ss.S':
          format = '##:##:##.#';
        break;
        case 'h:mm:ss.SS':
          format = '##:##:##.##';
        break;
        case 'h:mm:ss.SSS':
          format = '##:##:##.###';
        break;
      }

      return format;
    },
    selectTableById(id) {
      let selected_table = null;
      this.tables.some(function (table) {
        if (table.airtable_table_id === id) {
          selected_table = table;
          return true;
        }
      });

      if (selected_table) {
        this.onTableSelect(selected_table);
      }
    },
    selectRecordById(id) {
      const selected_item = {
        'id': id,
        fields: {}
      };
      this.onSelectRecord(selected_item);
    }
  },
  watch: {
    '$route.params': {
      handler(newParams, oldParams) {
        if (newParams.id !== this.selectedTable.airtable_table_id) {
          this.selectTableById(newParams.id);
        }

        if (newParams.record_id &&
            newParams.record_id !== oldParams.record_id) {
          this.selectRecordById(newParams.record_id);
        }
      }
    }
  },
  async beforeCreate() {
    const { getAccessTokenSilently } = useAuth0();
    const apiToken = await getAccessTokenSilently();
    useApplicationStore().setPageTitle(apiToken);
  },
  mounted() {
    this.apiRequest('post', '/api/tables').then(response => {
      if (response.data.status) {
          this.tables = response.data.tables;
          this.records = response.data.records;
          this.recordIds = [];
          for (let i=0; i<this.records.length; i++) {
            this.recordIds.push(this.records[i].id);
          }
          if (this.$route.params.id) {
            this.selectTableById(this.$route.params.id);
          } else {
            this.$router.replace({ name: 'selected-table', params: { id: this.tables[0].airtable_table_id } });
          }
          if (this.$route.params.record_id) {
            this.selectRecordById(this.$route.params.record_id);
          }
          this.isLoadingTableContent = false;
      }
      else{
        this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
      }
    });
  }
}
</script>

<style scoped>

</style>
