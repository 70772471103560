<template>
  <router-view/>
  <notifications>
    <template #body="props">
      <div class="mt-3 text-white rounded-2xl shadow-main py-5 px-5 mt-2" :class="props.item.type">
        <div class="w-full flex space-x-15px">
          <div class="flex-grow space-y-[2px]">
            <h6 class="text-sm font-bold">{{ props.item.title }}</h6>
            <p>{{ props.item.text }}</p>
          </div>
          <button class="w-6 h-6 relative flex justify-center items-center" @click="closeNotificationPopup(props.item.id)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34" class="w-full h-full -rotate-90">
              <circle cx="16" cy="16" r="15.9155" class="fill-transparent stroke-success stroke-2"></circle>
              <circle cx="16" cy="16" r="15.9155" class="fill-transparent stroke-white stroke-2 progress-bar__progress animate-progress"></circle>
            </svg>
            <figure class="absolute">
              <img src="@/assets/images/close-white.png" alt="Close icon">
            </figure>
          </button>
        </div>
      </div>

<!--      <div class="my-notification">-->
<!--        <p class="title">-->
<!--          {{ props.item.title }}-->
<!--        </p>-->
<!--        <button class="close" @click="close">-->
<!--          <i class="fa fa-fw fa-close"></i>-->
<!--        </button>-->
<!--        <div v-html="props.item.text"/>-->
<!--      </div>-->
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  methods:{
    closeNotificationPopup(id){
      this.$notify.close(id);
    }
  },
}
</script>

<style>
@import "assets/css/main.css";
@import "assets/css/tailwind.css";
</style>
