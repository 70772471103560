<template>
  <main class="bg-body min-h-screen w-full max-w-full overflow-hidden flex">
    <settings-left-sidebar tab="tables" :isApiValid="isApiValid"></settings-left-sidebar>
    <div class="flex-grow max-w-full relative md:pl-[86px] xl:pl-[295px] pb-20">
      <Header></Header>
      <section class="py-4 pl-5 sm:pl-8 lg:pl-15 pr-5 sm:pr-6 lg:pr-9 space-y-30px relative">
        <h2>Table Configuration</h2>
          <img v-if="isLoadingTables" src="@/assets/images/loader.gif" width="32">
          <div class="space-y-[10px]">
            <div v-for="(item, index) in tables" :key="index" class="w-full shadow-main bg-body rounded">
              <div class="bg-white rounded py-15px px-5 flex flex-col md:flex-row md:items-center">
                <p class="flex items-center space-x-[10px]">
                  <span class="font-bold text-default">{{ item.airtable_table_name }}</span>
                  <span class="text-info">{{ item.airtable_table_id }}</span>
                </p>
                <div class="w-full md:w-auto md:ml-auto mt-3 md:mt-0 flex-row-reverse md:flex-row md:space-x-[10px] flex items-center mr-[10px]">
                  <button @click="onTableExpand(item)" type="button" class="flex items-center justify-center space-x-[5px] bg-white shadow-btn px-[15px] py-[5px] rounded">
                    <span class="text-bold text-label">{{ item.showDetails ? 'Show Less' : 'Show Details' }}</span>
                    <figure class="w-6 h-6 flex items-center justify-center">
                      <img src="@/assets/images/chevron-up.png" :class="{ 'hidden' : item.showDetails }">
                      <img src="@/assets/images/chevron-down.png" :class="{ 'hidden' : !item.showDetails }">
                    </figure>
                  </button>
                </div>
                <button @click="onTableDelete(item, index)" type="button" class="w-6 h-6 min-w-fit flex items-center justify-center ml-auto md:ml-0">
                  <img src="@/assets/images/trash-icon-black.png" alt="Trash icon">
                </button>
              </div>
              <div :class="{ 'hidden': !item.showDetails }" class="space-y-5 p-5 transition-all">
                <div v-if="!item.columns.length" class="space-x-[10px] text-info flex items-center">
                  <figure class="flex items-center justify-center w-6 h-6">
                    <img src="@/assets/images/info-gray.png" alt="Info icon">
                  </figure>
                  <p>There are no fields attached to this table.</p>
                </div>
                <template v-if="item.columns.length">
                  <div class="flex items-center">
                    <span class="text-[10px] text-default uppercase font-semibold">Table Fields</span>
                    <button type="button" @click="onDeleteTableColumns(item, index)" class="text-[10px] text-theme-red uppercase font-semibold ml-auto">Remove All</button>
                  </div>
                  <section>
                    <div v-for="(colItem,colIndex) in item.columns" :key="colIndex" class="w-full pt-15px space-y-30px">
                      <div class="flex items-center space-x-15px" :class="{ 'alert-warning' : !colItem.is_valid }">
                        <button class="w-4 h-4 min-w-fit flex items-center justify-center">
                          <img src="@/assets/images/burger-menu-gray.png" alt="Burger menu icon">
                        </button>
                        <div class="w-[239px] min-w-fit">
                          <input type="text" name="field_name" placeholder="Field Name" autocomplete="off" v-model="colItem.airtable_column_name" :ref="'col_name_' + index + '_' + colIndex" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                        </div>
                        <div class="w-[239px] min-w-fit">
                          <input type="text" name="key" placeholder="Key" autocomplete="off" v-model="colItem.airtable_column_id" :ref="'col_id_' + index + '_' + colIndex" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                        </div>
                        <div class="w-36 relative min-w-fit">
                          <button type="button" @click="onOpenSelectTypeModal(colItem.airtable_column_type, index, colIndex)" class="w-full rounded-[10px] bg-white flex items-center border border-theme-gray py-3 px-4 text-sm text-label font-normal peer">
                            <span class="whitespace-nowrap">
                              {{ colItem.airtable_column_type && columnTypeLabels[colItem.airtable_column_type] ? columnTypeLabels[colItem.airtable_column_type] : 'Select type' }}
                            </span>
                            <figure class="w-6 h-6 min-w-fit flex items-center justify-center ml-auto">
                              <img src="@/assets/images/chevron-down.png" alt="Chevron down">
                            </figure>
                          </button>
                        </div>
                        <button type="button" @click="onDeleteTableColumn(item, index, colIndex)" class="w-6 h-6 min-w-fit flex items-center justify-center">
                          <img src="@/assets/images/trash-icon-black.png" alt="Trash icon">
                        </button>
                        <div v-if="colItem.error_message" class="space-x-[10px] text-info flex items-center">
                          <figure class="flex items-center justify-center w-6 h-6">
                            <img src="@/assets/images/info-gray.png" alt="Info icon">
                          </figure>
                          <p>{{ colItem.error_message }}</p>
                        </div>
                      </div>
                    </div>
                  </section>
                </template>
                <div class="w-full">
                  <button type="button" @click="onAddTableColumn(item)" class="flex items-center space-x-2">
                    <figure class="flex items-center justify-center w-6 h-6">
                      <img src="@/assets/images/add-icon.png" alt="Add icon">
                    </figure>
                    <span>Add field</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        <div v-if="noTablesFound" class="space-x-[10px] text-info flex items-center">
          <figure class="flex items-center justify-center w-6 h-6">
            <img src="@/assets/images/info-gray.png" alt="Info icon">
          </figure>
          <p>There are no tables yet.</p>
        </div>

        <div v-if="!isLoadingTables" class="w-full">
          <button @click="onShowCreateTableModal" class="flex items-center space-x-2">
            <figure class="flex items-center justify-center w-6 h-6">
              <img src="@/assets/images/add-icon.png" alt="Add icon">
            </figure>
            <span>Add Table</span>
          </button>
        </div>
        <div v-if="tables.length" class="max-w-full space-y-4">
          <button v-if="!tableSaveLoading" type="button" @click="onSaveTableConfiguration" class="px-6 h-11 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Finish Configuration</button>
          <button v-if="tableSaveLoading" disabled class="h-11 px-6 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded">Processing...</button>
        </div>
      </section>
      <section class="py-4 pl-5 sm:pl-8 lg:pl-15 pr-5 sm:pr-6 lg:pr-9 space-y-30px relative">
        <h2>Tables on Remote</h2>
        <img v-if="isLoadingTables" src="@/assets/images/loader.gif" width="32">
        <div class="space-y-[10px]">
          <div v-for="(item, index) in remote_tables" :key="index" class="w-full shadow-main bg-body rounded">
            <div class="bg-white rounded py-15px px-5 flex flex-col md:flex-row md:items-center">
              <p class="flex items-center space-x-[10px]">
                <span class="font-bold text-default">{{ item.airtable_table_name }}</span>
                <span class="text-info">{{ item.airtable_table_id }}</span>
              </p>
              <div class="w-full md:w-auto md:ml-auto mt-3 md:mt-0 flex-row-reverse md:flex-row md:space-x-[10px] flex items-center mr-[10px]">
                <button @click="onTableSync(item, index)" type="button" class="flex items-center justify-center space-x-[5px] bg-white shadow-btn px-[15px] py-[5px] rounded">
                  <span class="text-bold text-label">Sync Table</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <vue-final-modal
          v-model="showCreateTableModel"
          classes="flex justify-center items-center"
          content-class="relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900"
      >
        <div class="">
          <div class="rounded-2xl bg-white shadow-main w-[450px] max-w-full">
            <form @submit.prevent="onTableCreate">
              <div class="p-[25px] space-y-[25px]">
                <div class="flex items-center">
                  <h2 class="mr-4">New Table</h2>
                  <button type="button" @click="onCloseCreateTableModal" class="ml-auto w-6 h-6 flex justify-center items-center">
                    <img src="@/assets/images/close-black.png" alt="Close icon">
                  </button>
                </div>
                <div class="w-full space-y-5">
                  <div class="space-y-[5px] w-full">
                    <label for="create_table_name" class="font-bold text-label text-xs">Table name</label>
                    <input type="table-name" id="create_table_name" name="table-name" v-model="createTable.name" required="required" placeholder="e.g. Company" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                  </div>
                  <div class="space-y-[5px] w-full">
                    <label for="create_table_id" class="font-bold text-label text-xs">Table ID</label>
                    <input type="table-id" id="create_table_id" name="table-id" v-model="createTable.id" required="required" placeholder="tbllkjnl7890lka" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                  </div>
                </div>
              </div>
              <div class="border-t border-theme-gray px-[25px] py-5 flex justify-end">
                <button type="submit" class="ml-auto bg-primary hover:bg-[#005BE1] transition-all duration-300 text-white flex space-x-[10px] py-[11px] px-5 rounded">
                  <span>Add</span>
                  <figure class="w-6 h-6 flex items-center justify-center">
                    <img src="@/assets/images/check-white.png" alt="Check white">
                  </figure>
                </button>
              </div>
            </form>
          </div>
        </div>
      </vue-final-modal>

      <figure class="absolute -left-32 md:-left-3 xl:left-44 -bottom-36 md:-bottom-32">
        <img src="@/assets/images/circle-frame.png" alt="Circle frame">
      </figure>

      <vue-final-modal
          v-model="showManageColumnTypeModal"
          classes="flex justify-center items-center"
          content-class="relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900"
      >
        <div class="">
          <div class="rounded-2xl bg-white shadow-main w-[450px] max-w-full">
            <div class="p-[25px] space-y-[25px]">
              <div class="flex items-center">
                <h2 class="mr-4">
                  Select Type
                </h2>
                <button type="button" @click="onCloseColumnTypeModal" class="ml-auto w-6 h-6 flex justify-center items-center">
                  <img src="@/assets/images/close-black.png" alt="Close icon">
                </button>
              </div>
              <div class="w-full space-y-5">
                <div class="space-y-[5px] w-full">
                  <div>
                    <input type="text" placeholder="Find a field type" v-model="columnTypeSearchKeyword" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary padding-9">
                  </div>
                  <ul class="column-type-container">
                    <li @click="onColumnTypeSelect(item)" v-for="(item,index) in onFilterKeywordSearch()" :key="index" :class="{ 'active-row': columnTypeSelectedType == item.type }">
                      <span v-html="item.icon" class="inline-block"></span>
                      <span class="column-type-text">{{ item.name }}</span>
                    </li>
                  </ul>
                  <div v-if="columnTypeSelectedType == 'link_to_another_record'">
                    <ul class="column-date-options-list">
                      <li>
                        <p>Table</p>
                        <select v-model="selectedTableColumnRelationOptions.reference_table_id" class="w-full custom-select">
                          <option value="" selected="selected">Select</option>
                          <option v-for="item in onFilterReferenceTables()" :value="item.table_id" :key="item">
                            {{ item.airtable_table_name }}
                          </option>
                        </select>
                      </li>
                      <li v-if="selectedTableColumnRelationOptions.filter_by?.length">
                        <div class="w-full">Default Filter By</div>
                        <ul class="column-filter-container">
                          <li v-for="(item,index) in selectedTableColumnRelationOptions.filter_by" :key="index">
                            <input type="checkbox" :id="'filter_by_' + index" v-model="item.selected" /> {{ item.airtable_table_name }}
                          </li>
                        </ul>
                      </li>
                      <li :class="{ 'hidden' : !selectedTableColumnRelationOptions.reference_table_id }">
                        <p>Column</p>
                        <select v-model="selectedTableColumnRelationOptions.reference_column_id" class="w-full custom-select">
                          <option value="" selected="selected">Select</option>
                          <option v-for="item in onFilterReferenceColumns()" :value="item.column_id" :key="item">
                            {{ item.airtable_column_name }}
                          </option>
                        </select>
                      </li>
                      <li>
                        <div class="w-full flex items-center relative mt-3">
                          <input type="checkbox" id="allow_multiple_references" v-model="selectedTableColumnRelationOptions.allow_multiple_references" name="allow_multiple_references" class="sr-only peer">
                          <label for="allow_multiple_references" class="flex items-center h-5 cursor-pointer font-normal text-label text-xs pl-[50px] relative before:absolute before:left-0 before:w-10 before:h-5 before:bg-theme-gray before:transition-all before:duration-500 before:rounded-[6px] peer-checked:before:bg-theme-red after:w-[15px] after:h-[15px] after:rounded-[4px] after:absolute after:top-[2px] after:bottom-[2px] after:left-[3px] after:transition-all after:duration-500 after:bg-white peer-checked:after:left-[22px]">Allow linking to multiple records</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div v-if="['single_select','multiple_select'].indexOf(columnTypeSelectedType) != -1">
                    <ul class="column-type-multi-options" v-if="columnTypeMultipleOptionsList.length">
                      <li v-for="(item,index) in columnTypeMultipleOptionsList" :key="index" :class="{ 'active-row': columnTypeMultiSelectedType == index }">
                        <span class="inline-block mts-3 cursor-pointer drag-multi-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" style="shape-rendering: geometricprecision;" class="icon"><path fill-rule="evenodd" fill="currentColor" d="M5 3.505C5 3.226 5.214 3 5.505 3h.99c.279 0 .505.214.505.505v.99A.497.497 0 0 1 6.495 5h-.99A.497.497 0 0 1 5 4.495v-.99zm4 0C9 3.226 9.214 3 9.505 3h.99c.279 0 .505.214.505.505v.99a.497.497 0 0 1-.505.505h-.99A.497.497 0 0 1 9 4.495v-.99zm-4 4C5 7.226 5.214 7 5.505 7h.99c.279 0 .505.214.505.505v.99A.497.497 0 0 1 6.495 9h-.99A.497.497 0 0 1 5 8.495v-.99zm4 0C9 7.226 9.214 7 9.505 7h.99c.279 0 .505.214.505.505v.99a.497.497 0 0 1-.505.505h-.99A.497.497 0 0 1 9 8.495v-.99zm-4 4c0-.279.214-.505.505-.505h.99c.279 0 .505.214.505.505v.99a.497.497 0 0 1-.505.505h-.99A.497.497 0 0 1 5 12.495v-.99zm4 0c0-.279.214-.505.505-.505h.99c.279 0 .505.214.505.505v.99a.497.497 0 0 1-.505.505h-.99A.497.497 0 0 1 9 12.495v-.99z"></path></svg>
                        </span>
                        <input type="text" class="w-[300px]" v-model="item.value">
                        <span class="inline-block float-right cursor-pointer mts-3 delete-multi-icon" @click.prevent="deleteMultiSelectOption(index)">
                          <svg width="12" height="12" viewBox="0 0 12 12" style="shape-rendering: geometricprecision;" class="icon"><path fill-rule="evenodd" fill="currentColor" d="M7.41421356,6 L9.88226406,3.5319495 C10.0816659,3.33254771 10.0828664,3.01179862 9.88577489,2.81470708 L9.18529292,2.11422511 C8.97977275,1.90870494 8.66708101,1.91870543 8.4680505,2.11773594 L6,4.58578644 L3.5319495,2.11773594 C3.33254771,1.91833414 3.01179862,1.91713357 2.81470708,2.11422511 L2.11422511,2.81470708 C1.90870494,3.02022725 1.91870543,3.33291899 2.11773594,3.5319495 L4.58578644,6 L2.11773594,8.4680505 C1.91833414,8.66745229 1.91713357,8.98820138 2.11422511,9.18529292 L2.81470708,9.88577489 C3.02022725,10.0912951 3.33291899,10.0812946 3.5319495,9.88226406 L6,7.41421356 L8.4680505,9.88226406 C8.66745229,10.0816659 8.98820138,10.0828664 9.18529292,9.88577489 L9.88577489,9.18529292 C10.0912951,8.97977275 10.0812946,8.66708101 9.88226406,8.4680505 L7.41421356,6 L7.41421356,6 Z"></path></svg>
                        </span>
                      </li>
                    </ul>
                    <hr class="mt-3">
                    <span class="flex items-center justify-center mt-3" v-if="!columnTypeMultipleOptionsList.length">No options defined</span>
                    <br>
                    <span class="flex items-center justify-center mt-3">
                      <a href="" class="add-option-btn " @click.prevent="addMultiSelectOption">
                        <span class="inline-block">
                            <svg width="12" height="12" viewBox="0 0 12 12" style="shape-rendering: geometricprecision;" class="mr1"><path fill-rule="evenodd" fill="currentColor" d="M9.5,5 L7,5 L7,2.5 C7,2.224 6.776,2 6.5,2 L5.5,2 C5.224,2 5,2.224 5,2.5 L5,5 L2.5,5 C2.224,5 2,5.224 2,5.5 L2,6.5 C2,6.776 2.224,7 2.5,7 L5,7 L5,9.5 C5,9.776 5.224,10 5.5,10 L6.5,10 C6.776,10 7,9.776 7,9.5 L7,7 L9.5,7 C9.776,7 10,6.776 10,6.5 L10,5.5 C10,5.224 9.776,5 9.5,5"></path></svg>
                        </span>
                        <span>Add an option</span>
                      </a>
                    </span>
                  </div>
                  <div v-if="columnTypeSelectedType == 'date'">
                    <ul class="column-date-options-list">
                      <li>
                        <select name="date_type" v-model="selectedTableColumnDateOptions.format" class="w-full custom-select">
                          <option value="local">
                            {{ columnTypeDateListOptions.local }}
                          </option>
                          <option value="friendly">
                            {{ columnTypeDateListOptions.friendly }}
                          </option>
                          <option value="us">
                            {{ columnTypeDateListOptions.us }}
                          </option>
                          <option value="eu">
                            {{ columnTypeDateListOptions.eu }}
                          </option>
                          <option value="iso">
                            {{ columnTypeDateListOptions.iso }}
                          </option>
                        </select>
                      </li>
                      <li>
                        <div class="w-full flex items-center relative">
                          <input type="checkbox" id="include_date_time" @change="onColumnDateTypeTimeSelect($event)" v-model="selectedTableColumnDateOptions.include_time" name="include_date_time" class="sr-only peer">
                          <label for="include_date_time" class="flex items-center h-5 cursor-pointer font-normal text-label text-xs pl-[50px] relative before:absolute before:left-0 before:w-10 before:h-5 before:bg-theme-gray before:transition-all before:duration-500 before:rounded-[6px] peer-checked:before:bg-theme-red after:w-[15px] after:h-[15px] after:rounded-[4px] after:absolute after:top-[2px] after:bottom-[2px] after:left-[3px] after:transition-all after:duration-500 after:bg-white peer-checked:after:left-[22px]">Include time</label>
                        </div>
                      </li>
                      <li :class="{ 'hidden': !selectedTableColumnDateOptions.include_time }">
                        <select name="date_type" v-model="selectedTableColumnDateOptions.time_format" class="w-full custom-select">
                          <option value="12">
                            12 hour
                          </option>
                          <option value="24">
                            24 hour
                          </option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <div v-if="columnTypeSelectedType == 'number'">
                    <ul class="column-date-options-list">
                      <li>
                        <select name="format" v-model="selectedTableColumnNumberOptions.format" class="w-full custom-select">
                          <option value="decimal">
                            Decimal (1.0)
                          </option>
                          <option value="integer">
                            Integer (2)
                          </option>
                        </select>
                      </li>
                      <li :class="{ 'hidden': selectedTableColumnNumberOptions.format == 'integer' }">
                        <select name="format" v-model="selectedTableColumnNumberOptions.precision" class="w-full custom-select">
                          <option v-for="index in 8" :value="index" :key="index">
                            1.{{ '0'.repeat(index) }}
                          </option>
                        </select>
                      </li>
                      <li>
                        <div class="w-full flex items-center relative">
                          <input type="checkbox" id="allow_negative_numbers" v-model="selectedTableColumnNumberOptions.allowNegativeNumbers" name="include_date_time" class="sr-only peer">
                          <label for="allow_negative_numbers" class="flex items-center h-5 cursor-pointer font-normal text-label text-xs pl-[50px] relative before:absolute before:left-0 before:w-10 before:h-5 before:bg-theme-gray before:transition-all before:duration-500 before:rounded-[6px] peer-checked:before:bg-theme-red after:w-[15px] after:h-[15px] after:rounded-[4px] after:absolute after:top-[2px] after:bottom-[2px] after:left-[3px] after:transition-all after:duration-500 after:bg-white peer-checked:after:left-[22px]">Allow negative numbers</label>
                        </div>
                      </li>
                      <li>
                        <label for="default_number">Prefill new cells with this number</label>
                        <input id="default_number" type="text" v-model="selectedTableColumnNumberOptions.defaultNumber" placeholder="Default number" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </li>
                    </ul>
                  </div>
                  <div v-if="columnTypeSelectedType == 'currency'">
                    <ul class="column-date-options-list">
                      <li>
                        <label for="currency_symbol">Currency symbol</label>
                        <input id="currency_symbol" type="text" v-model="selectedTableColumnCurrencyOptions.symbol" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </li>
                      <li>
                        <select name="format" v-model="selectedTableColumnCurrencyOptions.precision" class="w-full custom-select">
                          <option v-for="index in 7" :value="index == 1 ? 0 : index" :key="index">
                            $1{{ index == 1 ? '' : '.'  + '0'.repeat(index) }}
                          </option>
                        </select>
                      </li>
                      <li>
                        <div class="w-full flex items-center relative">
                          <input type="checkbox" id="currency_allow_negative_numbers" v-model="selectedTableColumnCurrencyOptions.allowNegativeNumbers" name="currency_allow_negative_numbers" class="sr-only peer">
                          <label for="currency_allow_negative_numbers" class="flex items-center h-5 cursor-pointer font-normal text-label text-xs pl-[50px] relative before:absolute before:left-0 before:w-10 before:h-5 before:bg-theme-gray before:transition-all before:duration-500 before:rounded-[6px] peer-checked:before:bg-theme-red after:w-[15px] after:h-[15px] after:rounded-[4px] after:absolute after:top-[2px] after:bottom-[2px] after:left-[3px] after:transition-all after:duration-500 after:bg-white peer-checked:after:left-[22px]">Allow negative numbers</label>
                        </div>
                      </li>
                      <li>
                        <label for="default_currency_number">Prefill new cells with this number</label>
                        <input id="default_currency_number" type="text" v-model="selectedTableColumnCurrencyOptions.defaultNumber" placeholder="Default number" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </li>
                    </ul>
                  </div>
                  <div v-if="columnTypeSelectedType == 'percent'">
                    <ul class="column-date-options-list">
                      <li>
                        <select name="format" v-model="selectedTableColumnPercentOptions.precision" class="w-full custom-select">
                          <option v-for="index in 8" :value="index-1" :key="index">
                            1{{ index == 1 ? '' : '.'  + '0'.repeat(index - 1) }}
                          </option>
                        </select>
                      </li>
                      <li>
                        <div class="w-full flex items-center relative">
                          <input type="checkbox" id="percent_allow_negative_numbers" v-model="selectedTableColumnPercentOptions.allowNegativeNumbers" name="percent_allow_negative_numbers" class="sr-only peer">
                          <label for="percent_allow_negative_numbers" class="flex items-center h-5 cursor-pointer font-normal text-label text-xs pl-[50px] relative before:absolute before:left-0 before:w-10 before:h-5 before:bg-theme-gray before:transition-all before:duration-500 before:rounded-[6px] peer-checked:before:bg-theme-red after:w-[15px] after:h-[15px] after:rounded-[4px] after:absolute after:top-[2px] after:bottom-[2px] after:left-[3px] after:transition-all after:duration-500 after:bg-white peer-checked:after:left-[22px]">Allow negative numbers</label>
                        </div>
                      </li>
                      <li>
                        <label for="default_percent_number">Prefill new cells with this number</label>
                        <input id="default_percent_number" type="text" v-model="selectedTableColumnPercentOptions.defaultNumber" placeholder="Default number" class="py-3 px-4 border border-theme-gray rounded-[10px] text-sm text-label font-normal w-full focus:outline-none focus:shadow-none focus:border-primary">
                      </li>
                    </ul>
                  </div>
                  <div v-if="columnTypeSelectedType == 'duration'">
                    <ul class="column-date-options-list">
                      <li>
                        <select name="format" v-model="selectedTableColumnDuration" class="w-full custom-select">
                          <option v-for="(item,index) in durationTypeOptionsList" :value="index" :key="index">
                            {{ item }}
                          </option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <div v-if="columnTypeSelectedType == 'rating'">
                    <ul class="column-date-options-list">
                      <li>
                        <label for="rating">Max</label>
                        <div>
                          <select name="format" id="rating" v-model="selectedTableColumnRating" class="w-full custom-select">
                            <option v-for="index in 10" :value="index" :key="index">
                              {{ index }}
                            </option>
                          </select>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showSaveColumnTypeBtn" class="border-t border-theme-gray px-[25px] py-5 flex justify-end">
              <button @click="onSaveColumnType" type="button" class="ml-auto bg-primary hover:bg-[#005BE1] transition-all duration-300 text-white flex space-x-[10px] py-[11px] px-5 rounded">
                <span>Add</span>
                <figure class="w-6 h-6 flex items-center justify-center">
                  <img src="@/assets/images/check-white.png" alt="Check white">
                </figure>
              </button>
            </div>
          </div>
        </div>
      </vue-final-modal>

      <vue-final-modal
          v-model="showDeleteConfirmModal"
          classes="flex justify-center items-center"
          content-class="relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900"
      >
          <div class="rounded-2xl bg-white shadow-main w-[450px] max-w-full">
            <div class="p-[25px] space-y-[25px]">
              <div class="flex items-center">
                <h2 class="mr-4">
                  Confirmation Required
                </h2>
                <button type="button" @click="onCloseDeleteConfirmModal()" class="ml-auto w-6 h-6 flex justify-center items-center">
                  <img src="@/assets/images/close-black.png" alt="Close icon">
                </button>
              </div>
              <div class="w-full space-y-5">
                <div class="alert-warning">
                    <template v-if="deleteItemOptions.type == 'table'">
                      Are you sure you want to delete this table? <strong>{{ deleteItemOptions.name }}</strong>
                    </template>
                    <template v-if="deleteItemOptions.type == 'table_all_columns'">
                      Are you sure you want to delete all columns of <strong>{{ deleteItemOptions.name }}</strong> table?
                    </template>
                    <template v-if="deleteItemOptions.type == 'column'">
                      Are you sure you want to delete this column?
                    </template>
                </div>
              </div>
              <div class="space-y-[5px] w-full">
                <div class="w-full flex items-center">
                  <button type="button" @click="onCloseDeleteConfirmModal()" class="flex justify-center space-x-[10px]">
                    <dd class="font-bold text-default">Cancel</dd>
                  </button>
                  <button type="button" @click="onDeleteProcess()" class="px-6 h-11 bg-theme-red hover:bg-theme-red-dark transition-all duration-300 text-white flex justify-center disabled:bg-theme-gray disabled:text-disabled items-center text-sm font-bold rounded shadow-main ml-auto">Yes, Delete</button>
                </div>
              </div>
            </div>
          </div>
      </vue-final-modal>
    </div>
  </main>
</template>
<script>
import jwt_encode from "jwt-encode";
import SettingsLeftSidebar from "@/shared/SettingsLeftSidebar";
import Header from "@/shared/Header";
import { VueFinalModal } from 'vue-final-modal'
import { useApplicationStore } from "@/stores/application";
import {useAuth0} from "@auth0/auth0-vue";
export default {
  name: "TableSettings",
  components: {
    Header,
    SettingsLeftSidebar,
    VueFinalModal
  },
  data(){
    return {
      /**Tables*/
      isApiValid: false,
      noTablesFound: false,
      isLoadingTables: true,
      showCreateTableModel: false,
      tables: [],
      remote_tables: [],
      tableSaveLoading: false,
      showDeleteConfirmModal: false,
      deleteItemOptions: {},

      /**Create Table*/
      createTable: {
        name: '',
        id: ''
      },
      columnTypes: [],
      columnTypeLabels:{},

      /**Create Column*/
      columnTypeSearchKeyword: '',
      selectedTableColumn: {
        tableIndex: null,
        columnIndex: null,
        tableId: null
      },
      showManageColumnTypeModal: false,
      showSaveColumnTypeBtn: false,
      columnTypeSelectedType: '',
      selectedTableColumnObj: {},

      /**Column Specific Types*/
      columnTypeMultipleOptionsList: [],

      /**Column Relation Table, Column*/
      selectedTableColumnRelationOptions: {
          reference_table_id: '',
          reference_column_id: '',
          allow_multiple_references: false
      },

      /**Date Type*/
      columnTypeDateListOptions: {},
      selectedTableColumnDateOptions: {
        format: 'local',
        include_time: '',
        time_format: '12',
      },

      /**Number Type Options*/
      selectedTableColumnNumberOptions: {
        format: 'decimal',
        precision: '1',
        allowNegativeNumbers: false,
        defaultNumber: ''
      },

      /**Currency Type Options*/
      selectedTableColumnCurrencyOptions: {
        symbol: '',
        precision: '',
        allowNegativeNumbers: false,
        defaultNumber: ''
      },

      /**Percent Type Options*/
      selectedTableColumnPercentOptions: {
        precision: '',
        allowNegativeNumbers: false,
        defaultNumber: ''
      },

      /**Duration Type Options*/
      durationTypeOptionsList: [],
      selectedTableColumnDuration: 'h:mm',

      /**Rating*/
      selectedTableColumnRating: 5
    }
  },
  computed: {

  },
  methods: {
    async apiRequest(method, uri, data, headers) {
      const token = await this.$auth0.getAccessTokenSilently();
      const url = process.env.VUE_APP_API_URL + uri;
      data = { token: jwt_encode(Object.assign({}, data, { api_key: process.env.VUE_APP_API_KEY }), process.env.VUE_APP_JWT_KEY) };
      headers = Object.assign({}, headers, {
        Authorization: 'Bearer ' + token
      });

      return this.$http.request({
        method: method,
        url: url,
        data: data,
        headers: headers
      });
    },
    /**Table*/
    onTableExpand(item) {
      item.showDetails = !item.showDetails;
    },
    onTableDelete(item, index) {
      this.deleteItemOptions = {
        name: item.airtable_table_name,
        index: index,
        type: 'table'
      }

      this.showDeleteConfirmModal = true;
    },
    onDeleteProcess() {
      switch (this.deleteItemOptions.type) {
        case 'table':
          this.tables.splice(this.deleteItemOptions.index,1);
        break;
        case 'table_all_columns':
          this.tables[this.deleteItemOptions.index].columns = [];
        break;
        case 'column':
          this.tables[this.deleteItemOptions.index].columns.splice(this.deleteItemOptions.column_index,1);
        break;
      }
      this.showDeleteConfirmModal = false;
    },
    onShowCreateTableModal(){
      this.createTable.name = '';
      this.createTable.id = '';
      this.showCreateTableModel = true;
    },
    onCloseCreateTableModal(){
      this.showCreateTableModel = false;
    },
    /**
     * Sync method to populate local table config with tables on Remote for review and saving to local database
     *
     * @param table
     * @param index
     */
    onTableSync(table, index) {
      this.tables.push(table);
      this.remote_tables.splice(index, 1);

      table.fields.some(function (field) {
        let column = {
          'column_id': null,
          'table_id': null,
          'airtable_column_id': field.id,
          'airtable_column_name': field.name,
          'airtable_column_type': field.type,
          'reference_table_id': field.options?.linkedTableId || null,
          'reference_table_column_id': field.options?.inverseLinkFieldId || null,
          'allow_multiple_references': field.options?.prefersSingleRecordLink || false,
          'precision': field.options?.precision || null,
          'default_number': field.options?.default_number || null,
          'allow_negative_numbers': true,
          'duration': field.options?.durationFormat || null,
          'rating': field.options?.max || null,
          'multiple_select_options': [],
          'date_format': field.options?.dateFormat?.name || null,
          'time_format': field.options?.timeFormat?.name || null,
          'include_time': field.options?.type === 'dateTime',
          'symbol': field.options?.symbol || null,
          'is_valid': true
        };

        if (field.type === 'number') {
          column.number_format = 'decimal';
        }

        // Use field result options for datetime properties (eg. LastModifiedTime)
        if (field.options?.result?.options) {
          column.date_format = field.options.result.options.dateFormat?.name || column.date_format;
          column.time_format = field.options.result.options.timeFormat?.name || column.time_format;
          if (field.options.result.type === 'dateTime') {
            column.include_time = true;
          }
        }

        let choices = field.options?.choices || [];
        choices.forEach((choice) => {
          column.multiple_select_options.push({
            value: choice.name
          })
        });

        this.convertType(column);
        this.convertIds(column);
        this.validateColumn(column);

        table.columns.push(column);
      }, this);
    },
    onTableCreate(){
      if (!this.createTable.name.toString().trim().length) {
        this.$notify({ type: 'bg-error', title: 'Please specify name of the table', id: Date.now(), duration: 5000 });
        return false;
      }

      if (!this.createTable.id.toString().trim().length) {
        this.$notify({ type: 'bg-error', title: 'Please specify id of the table', id: Date.now(), duration: 5000 });
        return false;
      }

      this.tables.push({
        table_id: null,
        airtable_table_id: this.createTable.id,
        airtable_table_name: this.createTable.name,
        columns: [],
      });
      this.onCloseCreateTableModal();
    },
    onAddTableColumn(item){
      item.columns.push({
        'column_id': null,
        'table_id': item.table_id,
        'airtable_column_id': '',
        'airtable_column_name': '',
        'airtable_column_type': '',
        'reference_table_id': '',
        'reference_table_column_id': '',
        'allow_multiple_references' : false,
        'is_valid': true
      });
    },
    onDeleteTableColumn(item, index, col_index){
      this.deleteItemOptions = {
        name: item.airtable_table_name,
        index: index,
        column_index: col_index,
        type: 'column'
      }

      this.showDeleteConfirmModal = true;
    },
    onDeleteTableColumns(item, index){
      this.deleteItemOptions = {
        name: item.airtable_table_name,
        index: index,
        type: 'table_all_columns'
      }
      this.showDeleteConfirmModal = true;
    },
    onSaveTableConfiguration(){
      this.tableSaveLoading = true;
      for (let i=0; i<this.tables.length; i++) {
        if (this.tables[i].columns.length) {
          for (let c=0; c<this.tables[i].columns.length; c++) {
            // Handle validation failures, these mainly come from syncing from remote
            if (!this.tables[i].columns[c].is_valid) {
              this.tables[i].showDetails = true;
              this.tableSaveLoading = false;
              setTimeout(()=>{
                this.$refs['col_name_' + i + '_' + c][0].focus()
              },200);
              this.$notify({ type: 'bg-info', title: 'Please fix errors for the table ' + this.tables[i].airtable_table_name, id: Date.now(), duration: 5000 });
              return;
            }

            if (!this.tables[i].columns[c].airtable_column_name.trim().length) {
              this.tables[i].showDetails = true;
              this.tableSaveLoading = false;
              setTimeout(()=>{
                this.$refs['col_name_' + i + '_' + c][0].focus()
              },200);
              this.$notify({ type: 'bg-info', title: 'Please specify the column name for the table ' + this.tables[i].airtable_table_name, id: Date.now(), duration: 5000 });
              return;
            }

            if (!this.tables[i].columns[c].airtable_column_id.trim().length) {
              this.tables[i].showDetails = true;
              this.tableSaveLoading = false;
              this.$refs['col_id_' + i + '_' + c][0].focus();
              this.$notify({ type: 'bg-info', title: 'Please specify the column ID for the table ' + this.tables[i].airtable_table_name, id: Date.now(), duration: 5000 });
              return;
            }

            if (!this.tables[i].columns[c].airtable_column_type) {
              this.tableSaveLoading = false;
              this.$notify({ type: 'bg-info', title: 'Please specify the column type for the table ' + this.tables[i].airtable_table_name, id: Date.now(), duration: 5000 });
              return;
            }
          }
        }
      }

      /**Process Save*/
      this.apiRequest('patch', '/api/save/tables', { tables: this.tables })
          .then(response => {
            this.tableSaveLoading = false;
            if (response.data.status) {
              this.$notify({ type: 'bg-success', title: 'Data updated successfully', id: Date.now(), duration: 5000 });
              this.onPageLoad();
            }
            else{
              this.$notify({ type: 'bg-error', title: response.data.error, id: Date.now(), duration: 5000 });
            }
          });
    },
    /**Column Type*/
    onOpenSelectTypeModal(type, table_index, column_index){
      this.columnTypeSelectedType = type;
      this.selectedTableColumn.tableIndex = table_index;
      this.selectedTableColumn.columnIndex = column_index;
      this.selectedTableColumn.tableId = this.tables[table_index].table_id;
      this.columnTypeSearchKeyword = '';
      this.showManageColumnTypeModal = true;
      this.selectedTableColumnRelationOptions.reference_table_id = '';
      this.selectedTableColumnRelationOptions.reference_column_id = '';
      this.selectedTableColumnRelationOptions.allow_multiple_references = false;

      switch (this.columnTypeSelectedType) {
        case 'link_to_another_record':
          this.selectedTableColumnRelationOptions.reference_table_id = this.tables[table_index].columns[column_index].reference_table_id;
          this.selectedTableColumnRelationOptions.reference_column_id = this.tables[table_index].columns[column_index].reference_table_column_id;
          this.selectedTableColumnRelationOptions.allow_multiple_references = this.tables[table_index].columns[column_index].allow_multiple_references;
          this.selectedTableColumnRelationOptions.filter_by = this.tables[table_index].columns[column_index].filter_by;
        break;
        case 'single_select':
        case 'multiple_select':
          this.columnTypeMultipleOptionsList = this.tables[table_index].columns[column_index].multiple_select_options ? this.tables[table_index].columns[column_index].multiple_select_options : [];
        break;
        case 'date':
          this.selectedTableColumnDateOptions = {
            format: this.tables[table_index].columns[column_index].date_format ? this.tables[table_index].columns[column_index].date_format : 'local',
            include_time: this.tables[table_index].columns[column_index].include_time ? true : false,
            time_format: this.tables[table_index].columns[column_index].time_format ? this.tables[table_index].columns[column_index].time_format : '12'
          };
        break;
        case 'number':
          this.selectedTableColumnNumberOptions = {
            format: this.tables[table_index].columns[column_index].number_format ? this.tables[table_index].columns[column_index].number_format : 'decimal',
            precision: this.tables[table_index].columns[column_index].number_format == 'decimal' ? (this.tables[table_index].columns[column_index].precision ? this.tables[table_index].columns[column_index].precision : 1) : 1,
            allowNegativeNumbers: this.tables[table_index].columns[column_index].allow_negative_numbers ?? false,
            defaultNumber: this.tables[table_index].columns[column_index].default_number
          };
        break
        case 'currency':
          this.selectedTableColumnCurrencyOptions = {
            symbol: this.tables[table_index].columns[column_index].symbol ? this.tables[table_index].columns[column_index].symbol : '$',
            precision: this.tables[table_index].columns[column_index].precision ? this.tables[table_index].columns[column_index].precision : 1,
            allowNegativeNumbers: this.tables[table_index].columns[column_index].allow_negative_numbers,
            defaultNumber: this.tables[table_index].columns[column_index].default_number
          };
        break;
        case 'percent':
          this.selectedTableColumnPercentOptions = {
            precision: this.tables[table_index].columns[column_index].precision ? this.tables[table_index].columns[column_index].precision : 1,
            allowNegativeNumbers: this.tables[table_index].columns[column_index].allow_negative_numbers,
            defaultNumber: this.tables[table_index].columns[column_index].default_number
          };
        break;
        case 'duration':
          this.selectedTableColumnDuration = this.tables[table_index].columns[column_index].duration ? this.tables[table_index].columns[column_index].duration : 'h:mm';
        break;
        case 'rating':
          this.selectedTableColumnRating = this.tables[table_index].columns[column_index].rating ? this.tables[table_index].columns[column_index].rating : '5';
        break;
      }

      this.selectedTableColumnObj = this.tables[table_index].columns[column_index];
      this.showSaveColumnTypeBtn = true;
    },
    onCloseColumnTypeModal(){
      this.showManageColumnTypeModal = false;
    },
    onColumnTypeSelect(item){
      this.columnTypeSelectedType = item.type;
      this.columnTypeMultipleOptionsList = [];
      this.showSaveColumnTypeBtn = true;
      switch (this.columnTypeSelectedType){
        case 'link_to_another_record':
          this.selectedTableColumnRelationOptions.reference_table_id = '';
          this.selectedTableColumnRelationOptions.reference_column_id = '';
          this.selectedTableColumnRelationOptions.allow_multiple_references = true;
        break;
        case 'currency':
          if (!this.selectedTableColumnCurrencyOptions.symbol) {
            this.selectedTableColumnCurrencyOptions.symbol = '$';
          }

          if (!this.selectedTableColumnCurrencyOptions.precision) {
            this.selectedTableColumnCurrencyOptions.precision = '0';
          }
        break;
        case 'percent':
          if (!this.selectedTableColumnPercentOptions.precision) {
            this.selectedTableColumnPercentOptions.precision = '0';
          }
        break;
        case 'duration':
          if (!this.selectedTableColumnDuration) {
            this.selectedTableColumnDuration = 'h:mm';
          }
        break;
        case 'rating':
          if (!this.selectedTableColumnRating) {
            this.selectedTableColumnRating = '5';
          }
        break;
      }
    },
    onSaveColumnType(){
      let current_table_column_obj = this.tables[this.selectedTableColumn.tableIndex].columns[this.selectedTableColumn.columnIndex];
          current_table_column_obj.airtable_column_type = this.columnTypeSelectedType;
      switch (this.columnTypeSelectedType){
        case 'link_to_another_record':
          current_table_column_obj.reference_table_id = this.selectedTableColumnRelationOptions.reference_table_id;
          current_table_column_obj.reference_table_column_id = this.selectedTableColumnRelationOptions.reference_column_id;
          current_table_column_obj.allow_multiple_references = this.selectedTableColumnRelationOptions.allow_multiple_references
        break;
        case 'single_select':
        case 'multiple_select':
          current_table_column_obj.multiple_select_options = this.columnTypeMultipleOptionsList;
        break;
        case 'date':
          current_table_column_obj.date_format = this.selectedTableColumnDateOptions.format;
          current_table_column_obj.include_time = this.selectedTableColumnDateOptions.include_time;
          current_table_column_obj.time_format = this.selectedTableColumnDateOptions.time_format;
        break;
        case 'number':
          current_table_column_obj.number_format = this.selectedTableColumnNumberOptions.format;
          current_table_column_obj.precision = this.selectedTableColumnNumberOptions.precision;
          current_table_column_obj.allow_negative_numbers = this.selectedTableColumnNumberOptions.allowNegativeNumbers;
          current_table_column_obj.default_number = this.selectedTableColumnNumberOptions.defaultNumber;
        break;
        case 'currency':
          current_table_column_obj.symbol = this.selectedTableColumnCurrencyOptions.symbol;
          current_table_column_obj.precision = this.selectedTableColumnCurrencyOptions.precision;
          current_table_column_obj.allow_negative_numbers = this.selectedTableColumnCurrencyOptions.allowNegativeNumbers;
          current_table_column_obj.default_number = this.selectedTableColumnCurrencyOptions.defaultNumber;
        break;
        case 'percent':
          current_table_column_obj.precision = this.selectedTableColumnPercentOptions.precision;
          current_table_column_obj.allow_negative_numbers = this.selectedTableColumnPercentOptions.allowNegativeNumbers;
          current_table_column_obj.default_number = this.selectedTableColumnPercentOptions.defaultNumber;
        break;
        case 'duration':
          current_table_column_obj.duration = this.selectedTableColumnDuration;
        break;
        case 'rating':
          current_table_column_obj.rating = this.selectedTableColumnRating;
        break;
      }

      this.validateColumn(current_table_column_obj);
      this.showManageColumnTypeModal = false;
      this.columnTypeSelectedType = '';
    },
    onPageLoad()
    {
      this.apiRequest('post', '/api/settings/tables')
        .then(response => {
          this.isLoadingTables = false;
          if (response.data.status) {
            this.isApiValid = response.data.is_api_valid;
            /**Prepare Tables*/
            if (response.data.tables) {

              for (let i=0; i<response.data.tables.length; i++) {
                if (response.data.tables[i].columns.length) {
                  for (let t=0; t<response.data.tables[i].columns.length; t++) {
                    response.data.tables[i].columns[t].is_valid = true;
                    if (['single_select','multiple_select'].indexOf(response.data.tables[i].columns[t].airtable_column_type) != -1) {
                      let multiple_options = [];
                      if (response.data.tables[i].table_id,response.data.tables[i].columns[t].multiple_select_options.length) {
                        let options_json = response.data.tables[i].columns[t].multiple_select_options.slice(1, response.data.tables[i].columns[t].multiple_select_options.length-1);
                        // If our values aren't quoted in the database, we need to quote them here for json parsing
                        if (options_json.charAt(0) !== "\"") {
                          options_json = "\"" + options_json.replaceAll(",", "\",\"") + "\"";
                        }
                        let get_options = JSON.parse("[" + options_json + "]");
                        for (let g=0; g<get_options.length; g++) {
                          multiple_options.push({
                            value: get_options[g]
                          })
                        }
                      }

                      response.data.tables[i].columns[t].multiple_select_options = multiple_options;
                    }
                  }
                }
              }

              this.tables = response.data.tables;
              this.columnTypes = response.data.column_types;
              for (let i=0; i<this.columnTypes.length; i++) {
                this.columnTypeLabels[this.columnTypes[i].type] = this.columnTypes[i].name;
              }
            }
            else{
              this.noTablesFound = true;
            }

            if (response.data.remote_tables) {
              this.remote_tables = response.data.remote_tables;
            }

            /**Column Date Type Labels*/
            let date_obj = new Date();
            let date_obj_item;
            let month_names = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            for (let type in response.data.date_column_types) {
              switch (type) {
                case 'local':
                case 'us':
                case 'eu':
                  date_obj_item = date_obj.toISOString().split('T')[0].split('-');
                  if (type == 'eu') {
                    response.data.date_column_types[type] += ' (' + [date_obj_item[2], date_obj_item[1], date_obj_item[0]].join('/') + ')';
                  }
                  else{
                    response.data.date_column_types[type] += ' (' + [date_obj_item[1], date_obj_item[2], date_obj_item[0]].join('/') + ')';
                  }
                break;
                case 'friendly':
                  response.data.date_column_types[type] += ' (' + month_names[date_obj.getMonth()] + ' ' + date_obj.getDate() + ', ' + date_obj.getFullYear() + ')';
                break;
                case 'iso':
                  response.data.date_column_types[type] += ' (' + date_obj.toISOString().split('T')[0] + ')';
                break;
              }
            }

            this.columnTypeDateListOptions = response.data.date_column_types;
            this.durationTypeOptionsList = response.data.duration_column_types;
          }
        });
    },
    /**Multi Select Dropdown*/
    addMultiSelectOption(){
      this.columnTypeMultipleOptionsList.push({
        value: ''
      });
      return false;
    },
    deleteMultiSelectOption(index) {
      this.columnTypeMultipleOptionsList.splice(index,1);
    },
    /**Date Type*/
    onColumnDateTypeTimeSelect(event) {
      this.selectedTableColumnDateOptions.include_time = event.target.checked;
    },

    onFilterKeywordSearch(){
      let column_types = this.columnTypes;
      /**Filter by Keyword*/
      if (this.columnTypeSearchKeyword.length) {
        let lower_keyword = this.columnTypeSearchKeyword.toLowerCase();
        column_types = this.columnTypes.filter(item => {
          return item.name.toLowerCase().startsWith(lower_keyword)
        });
      }

      /**Sort ascending*/
      return column_types.sort(function(a,b){
        return a.name.localeCompare(b.name);
      });
    },
    onFilterReferenceTables(){
      let tables = this.tables.filter(item => item.table_id && this.selectedTableColumn.tableId != item.table_id);
      /**Sort ascending*/
      if (tables) {
        return tables.sort(function(a,b){
          return a.airtable_table_name.localeCompare(b.airtable_table_name);
        });
      }

      return [];
    },
    onFilterReferenceColumns(){
      for (let i=0; i<this.tables.length; i++) {
        if (this.tables[i].table_id == this.selectedTableColumnRelationOptions.reference_table_id) {
          let cols = this.tables[i].columns.filter(item => item.column_id);
          if (cols) {
            /**Sort ascending*/
            return cols.sort(function(a,b){
              return a.airtable_column_name.localeCompare(b.airtable_column_name);
            });
          }
        }
      }

      return [];
    },
    onCloseDeleteConfirmModal(){
      this.showDeleteConfirmModal = false;
    },
    /**
     * Handle column properties validation
     *
     * @param column
     */
    validateColumn(column) {
      column.is_valid = true;
      column.error_message = null;

      // If we have non-integer values for these, we didn't successfully convert from Airtable to local table id
      if (column.reference_table_id) {
        if (!Number.isInteger(column.reference_table_id)) {
          column.is_valid = false;
          column.error_message = 'Unable to find matching reference table, you must import the reference table first';
        } else if (!Number.isInteger(column.reference_table_column_id)) {
          column.is_valid = false;
          column.error_message = 'Unable to find matching reference column';
        }
      }
    },
    /**
     * Convert Airtable ids to local database ids
     *
     * @param column
     */
    convertIds(column) {
      if (column.reference_table_id === null) {
        return;
      }

      this.tables.forEach(function (table) {
        if (table.airtable_table_id === column.reference_table_id) {
          column.reference_table_id = table.table_id;
          table.columns.forEach(function (value) {
            if (value.airtable_column_id === column.reference_table_column_id) {
              column.reference_table_column_id = value.column_id;
            }
          })
        }
      });
    },
    /**
     * Convert Airtable types to types utilized by local this application
     *
     * @param column
     */
    convertType(column) {
      // Convert to snake_case to handle the majority of conversions
      let new_type = column.airtable_column_type.match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(s => s.toLowerCase())
            .join('_');

      switch (new_type) {
        case 'multiple_record_links':
          new_type = 'link_to_another_record';
          break;
        case 'date_time':
          new_type = 'date';
          break;
        case 'multiline_text':
          new_type = 'long_text';
          break;
        case 'multiple_attachments':
          new_type = 'attachment';
          break;
        case 'single_collaborator':
          new_type = 'user';
          break;
        case 'auto_number':
          new_type = 'autonumber';
          break;
        case 'multiple_lookup_values':
          new_type = 'lookup';
          break;
        case 'multiple_selects':
          new_type = 'multiple_select';
          break;
      }

      column.airtable_column_type = new_type;
    }
  },
  async beforeCreate(){
    const { getAccessTokenSilently } = useAuth0();
    const apiToken = await getAccessTokenSilently();
    useApplicationStore().setPageTitle(apiToken);
  },
  mounted(){
    this.onPageLoad();
  }
}
</script>
