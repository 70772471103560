<template>
  <div>
    <button @click="logout">Log out</button>
  </div>
</template>
<script>
// Composition API
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: "LogOut",
  setup() {
    const auth0 = useAuth0();

    return {
      logout() {
        auth0.logout({ returnTo: window.location.origin });
      }
    };
  }
};
</script>